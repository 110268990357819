import React, { useEffect, useMemo, useState } from 'react';
import { deliveryId, uniqueID } from '../../utils/constants';
import { axiosInstance, decodeValue, encodeValue, formatRupiah, isEmptyData, sendAnalyticsCustomEvent, sendAnalyticsEvent, sendAnalyticsPageView } from '../../utils/helper';
import { useHistory } from 'react-router';
import { ArrowLeftIcon, ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { HeartIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { AutoVoucherIcon, DiscountIcon, DoubleNoteIcon, LoaderIcon, MotorbikeIcon, TicketDiscountIcon, UltraVoucherIcon, VerifyPinIcon } from '../../utils/svg';
import Footer from '../../components/Footer';
import qs from 'qs';
import CartDetail from '../../components/Cart';
import Voucher from '../../components/Voucher';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useToast from '../../components/Toast';
import TooltipInfo from '../../components/TooltipInfo';
import VoucherDetail from '../../components/Voucher/VoucherDetail';
import { BottomSheet } from 'react-spring-bottom-sheet';
import ProductSimple from '../../components/Product/simple';
import { ComboDetail } from '../../components/Product/combo';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../store/reducers/user';
import { clearPayment, setPayment } from '../../store/reducers/payment';
import DeliveryLocationDetail from '../../components/DeliveryLocation/LocationDetail';
import { HowToSetPinUltraValue, SetPinUltraValue, TooFar } from '../../components/Errors';
import Loader from '../../components/Loader';
import { listPaymentMethod } from '../../utils/constants';
import VerifyPin from '../../components/VerifyPin';

const Summary = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { showToast } = useToast();

	const user = useSelector((store) => store?.user);

	const [outlet, setOutlet] = useState(null);
	const [product, setProduct] = useState(null);
	const [item, setItem] = useState(null);
	const [cartItem, setCartItem] = useState(null);
	const [UVBalance, setUVBalance] = useState(null);
	const [cart, setCart] = useState(null);

	const [pathnerList, setPathnerList] = useState([]);
	const [selectedPathner, setSelectedPathner] = useState(null);
	const [paymentMethodList, setPaymentMethodList] = useState([]);

	const [myVoucherList, setMyVoucherList] = useState([]);
	const [b2bVoucherList, setB2bVoucherList] = useState([]);

	const [isLoadingPaymentMethodList, setisLoadingPaymentMethodList] = useState(false);
	const [isLoadingDeliveryList, setisLoadingDeliveryList] = useState(false);
	const [isShowDelivery, setIsShowDelivery] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isShowBottomSheet, setIsShowBottomSheet] = useState(false);
	const [isShowSimpleProduct, setIsShowSimpleProduct] = useState(false);
	const [isShowComboProduct, setIsShowComboProduct] = useState(false);
	const [isLoadingVoucherList, setisLoadingVoucherList] = useState(false);
	const [isShowVoucher, setIsShowVoucher] = useState(false);
	const [isShowMoreVoucher, setIsShowMoreVoucher] = useState(false);
	const [isShowPaymentMethod, setIsShowPaymentMethod] = useState(false);
	const [isLoadingUvBalance, setIsLoadingUvBalance] = useState(false);
	const [isShowDeliveryLocation, setIsShowDeliveryLocation] = useState(false);
	const [isTooFar, setIsTooFar] = useState(false);
	const [isShowSetupPin, setIsShowSetupPin] = useState(false);
	const [isLoadingCheckPinUltraValue, setIsLoadingCheckPinUltraValue] = useState(false);
	const [isUltraValueAvailable, setIsUltraValueAvailable] = useState(false);
	const [isShowVerifyPinUltraValue, setShowVerifyPinUltraValue] = useState(false);
	const [isInvalidPin, setIsInvalidPin] = useState(false);
	const [isShowTutor, setIsShowTutor] = useState(false);

	const [showVoucherDetail, setShowVoucherDetail] = useState({
		isOpen: false,
		data: null,
	});

	const [orderData, setOrderData] = useState({
		unquieid: uniqueID,
		customerID: encodeValue(user?.customerID),
		paymentReference: null,
		sender: {
			firstName: user?.firstName,
			email: user?.email,
			phone: user?.primary_phone,
		},
		deliveryType: null,
		deliveryFee: 0,
		deliverySubsidies: 0,
		notes: '',
		paymentType: {
			code: 'VADIRECTBCA',
			name: 'Virtual Account BCA',
			minimum_amount: 0,
			maximum_amount: 0,
			status: 'ACTIVE',
			fee: 0,
			image_url: 'https://uvdev.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2019-09-30T03%3A39%3A05.606Z1569814745-group.jpeg',
			image_size: 'w-9 h-auto',
			image_size_payment: 'w-16 h-auto',
		},
		origin: null,
		destinations: {
			address: user?.address,
			coordinate: {
				latitude: user?.latitude,
				longitude: user?.longitude,
			},
			village: user?.village,
			district: user?.district,
			city: user?.city,
			province: user?.state,
			postalCode: user?.postalCode,
			state: user?.state,
			country: user?.country,
			recipient: {
				firstName: user?.firstName,
				email: user?.email,
				phone: user?.primary_phone,
				lastName: user?.lastName,
        instruction: user?.detail,
			},
			items: [],
		},
		pin: null,
	});

	const orderSchema = Yup.object().shape({
		unquieid: Yup.string().required('Unique ID is required'),
		customerID: Yup.string().required('Customer ID is required'),

		sender: Yup.object().shape({
			firstName: Yup.string().required('First Name is required'),
			email: Yup.string().email('Invalid email format').required('Email is required'),
			phone: Yup.string().required('Phone number is required'),
		}),

		deliveryType: Yup.mixed()
			.test('is-delivery-valid', 'Delivery Type is required', (delivery) => {
				if (typeof delivery === 'object') {
					return true;
				}
				return false;
			})
			.required('Delivery is required'),

		notes: Yup.string().optional(),

		paymentType: Yup.mixed()
			.test('is-payment-type-valid', 'Payment Type is required', (payment) => {
				if (typeof payment === 'object' && payment?.code === 'ULTRA-VOUCHER') {
					return Number(UVBalance?.value || 0) > Number(paymentSummary?.totalPayment || 0);
				}
				if (typeof payment === 'object') {
					return true;
				}
				return false;
			})
			.required('Payment Type is required'),

		origin: Yup.object().shape({
			address: Yup.string().required('Origin address is required'),
			keywords: Yup.string().optional(),
			coordinate: Yup.object().shape({
				latitude: Yup.string().required('Origin latitude is required'),
				longitude: Yup.string().required('Origin longitude is required'),
			}),
			village: Yup.string().required('Origin village is required'),
			district: Yup.string().required('Origin district is required'),
			city: Yup.string().required('Origin city is required'),
			province: Yup.string().required('Origin province is required'),
			postalCode: Yup.string().required('Origin postal code is required'),
		}),

		destinations: Yup.object().shape({
			address: Yup.string().required('Destination address is required'),
			coordinate: Yup.object().shape({
				latitude: Yup.string().required('Destination latitude is required'),
				longitude: Yup.string().required('Destination longitude is required'),
			}),
			village: Yup.string().required('Destination village is required'),
			district: Yup.string().required('Destination district is required'),
			city: Yup.string().required('Destination city is required'),
			province: Yup.string().required('Destination province is required'),
			postalCode: Yup.string(),
			recipient: Yup.object().shape({
				firstName: Yup.string().required('Recipient First Name is required'),
				email: Yup.string().email('Invalid email format').required('Recipient email is required'),
				phone: Yup.string().required('Recipient phone number is required'),
			}),
			items: Yup.array().min(1, 'At least one item is required').required('Items are required'),
		}),
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: orderData,
		validationSchema: orderSchema,
		onSubmit: async (values, { resetForm }) => {
			await handlePayment(values, resetForm);
		},
	});

	const { errors, touched, handleSubmit, values, setValues, isSubmitting } = formik;
  console.log('values: ', values);
	console.log('errors: ', errors);

	const findFirstError = (errors) => {
		if (errors.destinations && errors.destinations.address) {
			return errors.destinations.address;
		}

		for (const key in errors) {
			if (typeof errors[key] === 'object' && errors[key] !== null) {
				const nestedError = findFirstError(errors[key]);
				if (nestedError) {
					return nestedError;
				}
			}
			if (typeof errors[key] === 'string') {
				return errors[key];
			}
		}
		return null;
	};

  useEffect(() => {
    sendAnalyticsPageView("summary_session");
  }, []);

  useEffect(() => {
    if (isShowPaymentMethod) {
      sendAnalyticsPageView("payment_methods_session"); 
    }
  }, [isShowPaymentMethod]);

  useEffect(() => {
    if (isShowVoucher) {
      sendAnalyticsPageView("voucher_session"); 
    }
  }, [isShowVoucher]);

	useEffect(() => {
		if (!isSubmitting) return;
		if (Object.keys(errors).length > 0 && Object.keys(touched).length > 0) {
			const firstError = findFirstError(errors);
			if (firstError) {
				showToast('error', firstError, 1000);
			}
		}
	}, [isSubmitting]);

	const handleGetStoreDetail = async () => {
		if (!cart?.store?.storeID || !user?.customerID) return;
		console.log('handleGetStoreDetail');
		try {
			let url = `store/storeDetails?unquieid=${uniqueID}&storeID=${cart?.store?.storeID}&customerID=${user?.customerID}`;

			if (user?.latitude && user?.longitude) {
				url += `&latitude=${user?.latitude}&longitude=${user?.longitude}`;
			}
			const res = await axiosInstance.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				const data = res.data?.result;
				setOutlet(data || null);
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleCart = async (data) => {
		console.log('handleCart');
		try {
			setIsLoading(true);
			let postData = {
				unquieid: uniqueID,
				availabilityID: deliveryId,
				availabilityName: 'Delivery',
				shopID: encodeValue(outlet?.storeID),
				customerID: user?.customerID,
			};
			if (data?.productType === 'Simple') {
				postData = {
					...postData,
					...data,
				};
			} else {
				postData = {
					...postData,
					...data,
					productTotalPrice: data?.totalPrice,
					productPrice: data?.price,
					comboset: JSON.stringify(data?.comboset),
				};
			}
			const url = `cart/${data?.type === 'add' ? 'createCart' : 'updateCartItem'}`;
			const res = await axiosInstance.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				setCartItem(null);
				setProduct(null);
				setItem(null);
				handleCloseBottomSheet();
				await handleGetCart();
			}
			setIsLoading(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoading(false);
		}
	};

	const handleGetCart = async () => {
		if (!user?.customerID) return;
		console.log('handleGetCart');
		try {
			setIsLoading(true);
			const url = `cart/loadCartDetails?unquieid=${uniqueID}&customerID=${encodeValue(user?.customerID)}&availabilityID=${deliveryId}`;
			const res = await axiosInstance.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res?.data?.status === 'ok') {
				setCart(res?.data?.result || null);
			}
			if (res?.data?.message === 'There are no items in your cart') {
        isEmptyData(outlet?.storeSlug) ? history.goBack() : history.push(`/outlet/${outlet?.storeSlug}`);
				// showToast('warning', res?.data?.message, 1000);
			}
			setIsLoading(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoading(false);
		}
	};

	const handleSetSelectedUfoodPathner = (serviceList = []) => {
		if (!serviceList?.length) return null;
		console.log('handleSetSelectedUfoodPathner');
		const ufood = serviceList?.find((service) => service?.name === 'UFood');
		if (Boolean(ufood ?? null)) {
			setSelectedPathner(ufood ?? null);
			if (!ufood?.vehicle?.length) return;
			return ufood?.vehicle?.[0] ?? null;
		}
    return null;
	};

	const handleGetPathnerList = async () => {
		if (!user?.customerID || !cart?.store?.storeAddress || !user?.address || isLoadingDeliveryList) return;
		console.log('handleGetPathnerList');
		try {
			setisLoadingDeliveryList(true);

			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			const dataPost = {
				unquieid: uniqueID,
				customerID: encodeValue(user?.customerID),
				sender: {
					firstName: user?.firstName,
					email: user?.email,
					phone: user?.primary_phone,
				},
				origin: {
					address: cart?.store?.storeAddress,
					coordinate: cart?.store?.coordinate,
					village: cart?.store?.village,
					district: cart?.store?.district,
					city: cart?.store?.city,
					province: cart?.store?.province,
					postalCode: cart?.store?.postalCode,
					keywords: cart?.store?.storeName,
				},
				destinations: {
					address: user?.address,
					coordinate: {
						latitude: user?.latitude,
						longitude: user?.longitude,
					},
					village: user?.village,
					district: user?.district,
					city: user?.city,
					province: user?.state,
					postalCode: user?.postalCode,
					state: user?.state,
					country: user?.country,
					recipient: {
						firstName: user?.firstName,
						email: user?.email,
						phone: user?.primary_phone,
						lastName: user?.lastName,
					},
					items: cart?.item || [],
				},
			};
			const url = `deliverypartners/loadPartnersList`;
			const res = await axiosInstance.post(url, JSON.stringify(dataPost), config);

			if (res?.data?.status === 'ok' && res?.data?.result?.length) {
				const { delivery_fee, delivery_subsidies } = res?.data || {};
				const result = res?.data?.result || [];
				const normalizedVehicleData = result?.map((item) => {
					item.vehicle = item.vehicle.map((vehicle) => {
						if (vehicle.vehicleType === 'MOTORCYCLE') {
							vehicle.vehicleType = 'bike';
						}
						if (vehicle.vehicleType === 'TRUCK175') {
							vehicle.vehicleType = 'Truck';
						}
						return vehicle;
					});
					return item;
				});
				const serviceList = normalizedVehicleData
					?.map((item) => item.vehicle)
					.flat()
					.reduce((acc, vehicle) => {
						const existingService = acc.find((service) => service.name === vehicle.name && service.serviceType === vehicle.serviceType);

						if (existingService) {
							existingService.vehicle.push(vehicle);
						} else {
							acc.push({
								name: vehicle.name,
								serviceType: vehicle.serviceType,
								vehicle: [vehicle],
							});
						}

						return acc;
					}, [])
					.sort((a, b) => {
						if (a.name === 'UFood') return -1;
						if (b.name === 'UFood') return 1;
						return 0;
					});
				setPathnerList(serviceList);
				const bike = handleSetSelectedUfoodPathner(serviceList);
        setValues((prev) => {
          return {
            ...prev,
            deliveryFee: Number(delivery_fee) || 0,
						deliverySubsidies: Number(delivery_subsidies) || 0,
            deliveryType: bike,
          };
        });
				handleCloseBottomSheet();
			} else {
				handleCloseBottomSheet();
				setPathnerList([]);
				setSelectedPathner(null);
				setIsShowBottomSheet(true);
				setIsTooFar(true);
			}
			setisLoadingDeliveryList(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingDeliveryList(false);
		}
	};

	const handleGetPaymentReference = async () => {
		if (!user?.customerID) return;
		try {
			console.log('handleGetPaymentReference');
			setIsLoading(true);
			const postData = {
				unquieid: uniqueID,
				customerID: encodeValue(user?.customerID),
			};

			const url = `Paymentuvcr/generatePaymentRefrence`;

			const res = await axiosInstance.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				const reference = res?.data?.paymentRefrence || '';
				setValues((prev) => {
					return {
						...prev,
						paymentReference: reference,
					};
				});
			}
			setIsLoading(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoading(false);
		}
	};

	const refreshToken = async () => {
		if (!user?.customerID || !user?.refresh_token) return;
		console.log('refreshToken');
		try {
			const response = await axiosInstance.post(`auth/refresh-token`, {
				refresh_token: user?.refresh_token,
				customer_id: decodeValue(user?.customerID),
			});

			if (response?.status === 200 && response?.data?.status === 'ok') {
				const data = response?.data?.data;
				dispatch(
					setUser({
						token: data?.token,
					}),
				);
			} else {
				showToast('error', 'please relogin', 1000);
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	useEffect(() => {
		dispatch(clearPayment());
	}, []);

	useEffect(() => {
		refreshToken();
	}, [user?.customerID, user?.refresh_token]);

	useEffect(() => {
		handleGetUVBalance();
		handleCheckPinUltraValue();
	}, [user?.token, user?.refresh_token]);

	useEffect(() => {
		handleGetCart();
		handleGetPaymentReference();
	}, [user?.customerID]);

	useEffect(() => {
		if (!cart?.store?.storeAddress) return;
		setValues((prev) => {
			return {
				...prev,
				origin: {
					address: cart?.store?.storeAddress || '',
					coordinate: cart?.store?.coordinate || {},
					village: cart?.store?.village || '',
					district: cart?.store?.district || '',
					city: cart?.store?.city || '',
					province: cart?.store?.province || '',
					postalCode: cart?.store?.postalCode || '',
					keywords: cart?.store?.storeName || '',
				},
				destinations: {
					address: user?.address,
					coordinate: {
						latitude: user?.latitude,
						longitude: user?.longitude,
					},
					village: user?.village,
					district: user?.district,
					city: user?.city,
					province: user?.state,
					postalCode: user?.postalCode,
					state: user?.state,
					country: user?.country,
					recipient: {
						firstName: user?.firstName,
						email: user?.email,
						phone: user?.primary_phone,
						lastName: user?.lastName,
					},
					items: cart?.item || [],
				},
			};
		});
	}, [cart?.store?.storeAddress]);

	useEffect(() => {
		handleGetVoucherList();
		handleGetStoreDetail();
	}, [cart?.store?.posID]);

	useEffect(() => {
		if (!user?.address) return;
		setValues((prev) => {
			return {
				...prev,
				destinations: {
					address: user?.address,
					coordinate: {
						latitude: user?.latitude,
						longitude: user?.longitude,
					},
					village: user?.village,
					district: user?.district,
					city: user?.city,
					province: user?.state,
					postalCode: user?.postalCode,
					state: user?.state,
					country: user?.country,
					recipient: {
						firstName: user?.firstName,
						email: user?.email,
						phone: user?.primary_phone,
						lastName: user?.lastName,
					},
					items: prev.destinations?.items || [],
				},
			};
		});
	}, [user?.address]);

	useEffect(() => {
		handleGetPathnerList();
	}, [user?.customerID, cart?.store?.storeAddress, user?.address]);

	const handleGetProductDetail = async (slug, data) => {
		console.log('handleGetProductDetail', slug);
		try {
			setIsLoading(true);
			const url = `catalogs/productdetails?unquieid=${uniqueID}&productID=${slug}`;
			const res = await axiosInstance.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				const product = res.data?.result || null;
				console.log('cart', cart);
				console.log('product: ', product);
				setCartItem(product);
				setProduct(product);
				setItem(data);
				setIsShowBottomSheet(true);
				if (product?.product_type === '1') {
					setIsShowSimpleProduct(true);
				}
				if (product?.product_type === '2') {
					setIsShowComboProduct(true);
				}
			}
			if (res?.data?.status === 'error') {
				showToast('warning', 'Product not found', 1000);
			}
			setIsLoading(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoading(false);
		}
	};

	const handleFavorite = async () => {
		console.log('handleFavorite');
		try {
			const dataPost = {
				unquieid: uniqueID,
				shopID: encodeValue(cart?.store?.storeID),
				customerID: encodeValue(user?.customerID),
			};
			const url = `store/${Boolean(Number(outlet?.favorite || outlet?.favourite)) ? 'removeFavourite' : 'addFavourite'}`;
			const res = await axiosInstance.post(url, qs.stringify(dataPost));
			if (res?.data?.status === 'ok') {
				handleGetStoreDetail();
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const groupByNominal = async (b2bVoucherList = []) => {
		console.log('groupByNominal');
		const result = [];

		b2bVoucherList?.forEach((voucher) => {
			const existingVoucher = result.find((v) => v.nominal === voucher.nominal);

			if (existingVoucher) {
				existingVoucher.total_is_use += voucher.is_use ? 1 : 0;
				existingVoucher.total_stock_available += voucher.stock_available;
			} else {
				result.push({
					...voucher,
					total_is_use: voucher.is_use ? 1 : 0,
					total_stock_available: voucher.stock_available,
				});
			}
		});

		return result;
	};

	const autoApplyMyVoucher = async (voucherMy = []) => {
		if (!voucherMy?.length) return [];
		console.log('autoApplyMyVoucher');

		const myVouchersList = [];

		let totalMyNominal = 0;

		const cartSubTotal = Number(cart?.subTotal) || 0;

		for (let my of voucherMy || []) {
			let isUse = my?.is_use ?? false;
			if (totalMyNominal + my?.nominal_voucher <= cartSubTotal) {
				totalMyNominal += my?.nominal_voucher;
				isUse = true;
			}
			myVouchersList?.push({ ...my, is_use: isUse });
		}

		return myVouchersList;
	};

	const updateAutoApplyMyVoucher = async (voucherMy = []) => {
		if (!voucherMy?.length) return [];
		console.log('updateAutoApplyMyVoucher');

		const myVouchersList = [];

		let totalMyNominal = 0;

		const cartSubTotal = Number(cart?.subTotal) || 0;

		for (let my of voucherMy || []) {
			let isUse = my?.is_use ?? false;
			if (totalMyNominal + my?.nominal_voucher <= cartSubTotal) {
				totalMyNominal += my?.nominal_voucher;
				isUse = true;
			} else {
				isUse = false;
			}
			myVouchersList?.push({ ...my, is_use: isUse });
		}
		setMyVoucherList(myVouchersList);
	};

	const updateAutoApplyB2bVoucher = async (voucherB2b = []) => {
		if (!voucherB2b?.length) return [];
		console.log('updateAutoApplyB2bVoucher');

		const b2bVouchersList = [];

		for (let b2b of b2bVoucherList) {
			let totalIsUse = 0;
			b2bVouchersList.push({ ...b2b, total_is_use: totalIsUse });
		}
		setB2bVoucherList(b2bVouchersList);
	};

	useMemo(() => {
		if (isLoadingVoucherList || !cart?.subTotal) return;
		updateAutoApplyMyVoucher(myVoucherList);
		updateAutoApplyB2bVoucher(b2bVoucherList);
	}, [cart?.subTotal]);

	const handleApplyB2BVoucher = () => {
		console.log('handleApplyB2BVoucher');

		const b2bList = [];
		let totalB2BNominal = 0;

		let remainingNominal = Math.max(0, Number(cart?.subTotal) - paymentSummary?.myVoucherApplied);

		for (let b2b of b2bVoucherList) {
			let totalIsUse = 0;
			const availableStock = b2b?.total_stock_available || 0;
			const maxNominalUsage = Math.floor(remainingNominal / b2b.nominal);

			totalIsUse = Math.min(availableStock, maxNominalUsage);

			if (totalIsUse > 0) {
				totalB2BNominal += totalIsUse * b2b.nominal;
				remainingNominal -= totalIsUse * b2b.nominal;
			}

			b2bList.push({ ...b2b, total_is_use: totalIsUse });
		}
		setB2bVoucherList(b2bList);
	};

	const handleGetVoucherList = async () => {
		if (!cart?.store?.posID || !user?.token || !user?.refresh_token) return;
		console.log('handleGetVoucherList');
		try {
			setisLoadingVoucherList(true);

			let voucherMyGrouping = [];
			let totalMyVoucher = 0;
			let voucherMy = [];
			let voucherB2B = [];

			const postDataMy = {
				posID: cart?.store?.posID ?? '',
				token: user?.token,
				refresh_token: user?.refresh_token,
			};
			const myUrl = `voucher/list`;
			const resMyLimit = await axiosInstance.post(myUrl, qs.stringify(postDataMy), { timeout: 10 * 1000 });
			if (resMyLimit?.data?.status === 'ok') {
				voucherMyGrouping = resMyLimit?.data?.data || [];
				voucherMyGrouping?.forEach((voucher) => {
					totalMyVoucher += Number(voucher?.total_voucher) || 0;
				});
			}

			if (totalMyVoucher > 0) {
				postDataMy.page = 1;
				postDataMy.limit = totalMyVoucher;
				const resMy = await axiosInstance.post(myUrl, qs.stringify(postDataMy), { timeout: 10 * 1000 });
				if (resMy?.data?.status === 'ok') {
					voucherMy = resMy?.data?.data || [];
				}
			}

			const sortedMyVouchers = voucherMy?.sort((a, b) => new Date(a.expired_date) - new Date(b.expired_date));

			const postDataB2B = {
				merchantCode: cart?.store?.merchantCode ?? '',
				page: 1,
				limit: 10,
			};
			const b2bUrl = `voucher-b2b/list`;
			const resB2B = await axiosInstance.post(b2bUrl, qs.stringify(postDataB2B), { timeout: 10 * 1000 });
			if (resB2B?.data?.status === 'ok') {
				voucherB2B = resB2B?.data?.data || [];
			}

			const autoApplyMy = await autoApplyMyVoucher(sortedMyVouchers);
			setMyVoucherList(autoApplyMy);

			const groupB2BVoucher = await groupByNominal(voucherB2B);
			const sortedB2BVouchers = groupB2BVoucher?.sort((a, b) => a.nominal - b.nominal);
			setB2bVoucherList(sortedB2BVouchers);

			setisLoadingVoucherList(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingVoucherList(false);
		}
	};

	const handleUpdateVoucherList = (id, voucher_type, use = false, type = 'plus') => {
		console.log('handleUpdateVoucherList', id, voucher_type, use, type);
		const updateMyVoucher = (voucherArray) => {
			console.log('updateMyVoucher');
			return voucherArray.map((voucher) => {
				let isUse = voucher.is_use ?? false;
				if (voucher.id === id) {
					isUse = !use;
				}
				return {
					...voucher,
					is_use: isUse,
				};
			});
		};

		const updateB2BVoucher = (voucherArray) => {
			console.log('updateB2BVoucher');
			return voucherArray.map((voucher) => {
				let totalIsUse = voucher.total_is_use || 0;
				if (voucher.code === id && type === 'plus') {
					totalIsUse += 1;
				}
				if (voucher.code === id && type === 'minus') {
					totalIsUse -= 1;
				}
				return {
					...voucher,
					total_is_use: Math.max(0, totalIsUse),
				};
			});
		};

		if (voucher_type === 'b2b_voucher') {
			const updatedList = updateB2BVoucher(b2bVoucherList);
			setB2bVoucherList(updatedList);
		}
		if (voucher_type === 'my_voucher') {
			const updatedList = updateMyVoucher(myVoucherList);
			setMyVoucherList(updatedList);
		}
	};

	const handleGetUVBalance = () => {
		if (!user?.token || !user?.refresh_token) return;
		console.log('handleGetUVBalance');
		try {
			setIsLoadingUvBalance(true);
			const postData = {
				token: user?.token,
				refresh_token: user?.refresh_token,
			};
			const url = `ultra-value/balance`;
			axiosInstance.post(url, qs.stringify(postData)).then((res) => {
				if (res?.data?.status === 'ok') {
					const result = res?.data?.result?.data || {};
					setUVBalance({
						code: 'ULTRA-VOUCHER',
						name: result?.name ?? 'Ultra Value',
						value: result?.total_value ?? 0,
						image: result?.image?.url ?? '',
					});
				}
			});
			setIsLoadingUvBalance(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoadingUvBalance(false);
		}
	};

	const handleCheckPinUltraValue = async (pin = '', onValidate = false) => {
		if (!user?.token) return;
		console.log('handleCheckPinUltraValue');
		try {
			if (onValidate) {
				setIsLoading(true);
			} else {
				setIsLoadingCheckPinUltraValue(true);
			}
			const postData = {
				token: user?.token,
				pin: pin,
			};
			const url = `ultra-value/verify-pin`;
			const res = await axiosInstance.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				// has pin
				// "code": "CLE002",
				// "status": "error",
				// "message": "Value of PIN does not match"

				// has no pin
				// "code": "CLE011",
				// "status": "error",
				// "message": "Resource not found: PIN"

				const errorCode = res?.data?.result?.errors?.[0]?.code;
				const errorStatus = res?.data?.result?.errors?.[0]?.status;
				if (errorCode === 'CLE002' && errorStatus === 'error') {
					console.log('errorCode: ', errorCode, errorStatus);
					setIsUltraValueAvailable(true);
					if (onValidate) {
						setIsLoading(false);
						setIsInvalidPin(true);
					}
				} else {
					setIsUltraValueAvailable(false);
				}

				const successCode = res?.data?.result?.meta?.code;
				const successStatus = res?.data?.result?.meta?.status;
				if (successCode === 'SSR001' || successStatus === 'success') {
					handleCloseAndSubmitUltraValue(pin);
				}
			}
			if (!onValidate) {
				setIsLoadingCheckPinUltraValue(false);
			}
		} catch (error) {
			console.log('error: ', error);
			if (onValidate) {
				setIsLoading(false);
			} else {
				setIsLoadingCheckPinUltraValue(false);
			}
		}
	};

	const totalMyVoucherNominal = (voucherList) => {
		if (!voucherList?.length) return;
		console.log('totalMyVoucherNominal');
		const filterMyVoucher = voucherList?.filter((voucher) => voucher.is_use);
		const totalMyNominal = filterMyVoucher?.reduce((sum, voucher) => sum + voucher.nominal_voucher, 0);
		return totalMyNominal;
	};

	const totalB2BVoucherNominal = (voucherList) => {
		if (!voucherList?.length) return;
		console.log('totalB2BVoucherNominal');
		let totalB2BNominal = 0;
		b2bVoucherList.forEach((voucher) => {
			if (voucher.total_is_use) {
				for (let i = 0; i < voucher.total_is_use; i++) {
					totalB2BNominal += voucher.nominal;
				}
			}
		});
		return totalB2BNominal;
	};

	const totalB2BVoucherPrice = (voucherList) => {
		if (!voucherList?.length) return;
		console.log('totalB2BVoucherPrice');
		let totalB2BPrice = 0;
		b2bVoucherList.forEach((voucher) => {
			if (voucher.total_is_use) {
				for (let i = 0; i < voucher?.total_is_use; i++) {
					totalB2BPrice += voucher?.price_user;
				}
			}
		});
		return totalB2BPrice;
	};

	const totalNominalVoucher = useMemo(() => {
		return totalMyVoucherNominal(myVoucherList) || 0;
	}, [myVoucherList]);

	const totalNominalB2BVoucher = useMemo(() => {
		return totalB2BVoucherNominal(b2bVoucherList) || 0;
	}, [b2bVoucherList]);

	const totalPriceB2BVoucher = useMemo(() => {
		return totalB2BVoucherPrice(b2bVoucherList) || 0;
	}, [b2bVoucherList]);

	const maxNominalVoucher = useMemo(() => {
		return Number(totalNominalVoucher) + Number(totalNominalB2BVoucher) > Number(cart?.subTotal) ? Number(cart?.subTotal) : Number(totalNominalVoucher) + Number(totalNominalB2BVoucher);
	}, [totalNominalVoucher, totalNominalB2BVoucher, cart?.subTotal]);

	const remainingNominal = useMemo(() => {
		return Number(cart?.subTotal) - Number(maxNominalVoucher);
	}, [cart?.subTotal, maxNominalVoucher]);

	const paymentSummary = useMemo(() => {
		const subTotal = Number(cart?.subTotal || 0);
		const packagingFee = Number(cart?.packagingFee || 0);
		const platformFee = Number(cart?.platformFee || 0);
		const deliveryFee = values?.deliveryType?.amount ? Number(values?.deliveryType?.amount || 0) + Number(values?.deliveryFee) : 0;
		const paymentFee = Number(values?.paymentType?.fee || 0);

		const isDisableVoucher = totalNominalVoucher + totalNominalB2BVoucher > subTotal;

		const fixTotalVoucherNominal = isDisableVoucher ? subTotal : maxNominalVoucher;

		const totalPayment = subTotal + packagingFee + platformFee + deliveryFee + paymentFee + totalPriceB2BVoucher - fixTotalVoucherNominal;

		const otherFee = packagingFee + platformFee + paymentFee;

		return {
			subTotal: subTotal,
			totalPayment: totalPayment,
			deliveryFee: deliveryFee,
			myVoucherApplied: fixTotalVoucherNominal,
			otherFee: otherFee,
			packagingFee: packagingFee,
			platformFee: platformFee,
			paymentFee: paymentFee,
			isDisableVoucher: isDisableVoucher,
		};
	}, [cart, values, myVoucherList, b2bVoucherList, totalNominalVoucher, totalNominalB2BVoucher, maxNominalVoucher]);

	const suggestAutoB2BVoucher = () => {
		if (!b2bVoucherList?.length || !cart?.subTotal || totalNominalB2BVoucher) return;
		console.log('suggestAutoB2BVoucher');

		let totalB2BNominal = 0;
		let totalB2BPrice = 0;

		let remainingNominal = Math.max(0, Number(cart?.subTotal) - paymentSummary?.myVoucherApplied);

		for (let b2b of b2bVoucherList) {
			let totalIsUse = 0;
			const availableStock = b2b?.total_stock_available || 0;
			const maxNominalUsage = Math.floor(remainingNominal / b2b.nominal);

			totalIsUse = Math.min(availableStock, maxNominalUsage);

			if (totalIsUse > 0) {
				totalB2BNominal += totalIsUse * b2b.nominal;
				totalB2BPrice += totalIsUse * b2b.price_user;
				remainingNominal -= totalIsUse * b2b.nominal;
			}
		}

		const b2BVoucherSaved = Math.max(0, totalB2BNominal - totalB2BPrice);
		return b2BVoucherSaved;
	};

	const totalSavedB2BVoucher = useMemo(() => {
		return suggestAutoB2BVoucher() || 0;
	}, [b2bVoucherList, cart?.subTotal, paymentSummary?.myVoucherApplied]);

	const handleGetPaymentFee = async () => {
    // !cart?.subTotal || paymentSummary.totalPayment < Number(cart?.subTotal) ||  remove this validation to make sure get oayment fee
		if (!user?.token || !user?.refresh_token || !paymentSummary.totalPayment || isLoadingPaymentMethodList) return;
		console.log('handleGetPaymentFee');
		try {
			setisLoadingPaymentMethodList(true);
			const postData = {
				token: user?.token,
				refresh_token: user?.refresh_token,
				totalAmount: paymentSummary.totalPayment,
			};
			const url = `calculate-fee`;
			const res = await axiosInstance.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				const result = res?.data?.data || [];

				let currentPaymentType = null;
				const updatedPaymentMethodList = listPaymentMethod
					?.map((paymentCategory) => {
						const updatedPaymentMethods = paymentCategory.payment_method
							.map((method) => {
								const matchedCategory = result.find((item) => item.payment_method.some((pm) => pm.code === method.code && pm.status === 'ACTIVE'));
								if (matchedCategory) {
									const matchedMethod = matchedCategory.payment_method.find((pm) => pm.code === method.code);
									if (matchedMethod.code === values?.paymentType?.code) {
										currentPaymentType = matchedMethod;
									}
									return {
										...method,
										fee: matchedMethod?.fee || method.fee,
									};
								}
							})
							.filter((method) => method);
						return {
							...paymentCategory,
							payment_method: updatedPaymentMethods,
						};
					})
					.filter((category) => category?.payment_method?.length);
				setPaymentMethodList(updatedPaymentMethodList);
				setValues((prev) => {
					return {
						...prev,
						paymentType: currentPaymentType,
					};
				});
			}
			setisLoadingPaymentMethodList(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingPaymentMethodList(false);
		}
	};

	useEffect(() => {
		handleGetPaymentFee();
	}, [paymentSummary?.totalPayment]);

	const processB2BVouchers = (b2bVoucherList = []) => {
		console.log('processB2BVouchers');
		let result = [];

		b2bVoucherList.forEach((voucher) => {
			if (voucher.total_is_use) {
				for (let i = 0; i < voucher.total_is_use; i++) {
					let newVoucher = { ...voucher };
					delete newVoucher.total_is_use;
					delete newVoucher.total_stock_available;
					result.push({
						voucher_code: newVoucher?.code,
						voucher_nominal: newVoucher?.nominal,
						voucher_price: newVoucher?.price,
						voucher_merchant_code: newVoucher?.merchant_code,
						voucher_qty: 1,
						voucher_price_user: newVoucher?.price_user,
					});
				}
			}
		});

		return result;
	};

	const handleVerifyPinUltraValue = () => {
		console.log('handleVerifyPinUltraValue');
		setShowVerifyPinUltraValue(true);
	};

	const handleCloseVerifyPinUltraValue = () => {
		console.log('handleCloseVerifyPinUltraValue');
		setShowVerifyPinUltraValue(false);
	};

	const handleCloseAndSubmitUltraValue = (pin) => {
		console.log('handleCloseAndSubmitUltraValue');
		setIsLoading(true);
		setValues((prev) => {
			return {
				...prev,
				pin: pin,
			};
		});

		setTimeout(() => {
			handleSubmit();
		}, 1000);
	};

	const handlePayment = async (values, resetForm) => {
		console.log('handlePayment', isNeedVerifyPinUltraValue, isUltraValueAvailable, !values?.pin, values?.pin);
		try {
      const minimum = Number(values?.paymentType?.minimum_amount);
      const maximum = Number(values?.paymentType?.maximum_amount);
      console.log('cek minimum', Number(paymentSummary?.totalPayment), minimum);
      if (Number(paymentSummary?.totalPayment) < minimum) {
        return showToast('error', `minimum payment is ${formatRupiah(minimum)}`, 1000);
      }
      console.log('cek maximum', Number(paymentSummary?.totalPayment), maximum);
      if (Number(paymentSummary?.totalPayment) > maximum) {
        return showToast('error', `maximum payment is ${formatRupiah(maximum)}`, 1000);
      }
			if (isNeedVerifyPinUltraValue && !values?.pin) {
				if (!isUltraValueAvailable) {
					setIsShowBottomSheet(true);
					return setIsShowSetupPin(true);
				}
				return handleVerifyPinUltraValue();
			}
			console.log('check pin', values?.pin);

			setIsLoading(true);

			const myVoucher = myVoucherList?.filter((voucher) => voucher.is_use)?.map((voucher) => voucher?.voucher_id) || [];

			const b2bVoucher = processB2BVouchers(b2bVoucherList);

			const dataPost = {
				unquieid: uniqueID,
				customerID: encodeValue(user?.customerID),
				deliveryCharge: values?.deliveryType?.amount || 0,
				additionalDeliveryCharge: 0,
				taxCharge: 0,
				taxAmount: 0,
				subTotal: paymentSummary?.subTotal,
				grandTotal: paymentSummary?.totalPayment,
				totalDiscount: 0,
				discountType: '',
				totalItem: cart?.items?.length || 0,
				instantOrder: 'Yes',
				availabilityID: deliveryId,
				availabilityName: 'Delivery',
				orderSource: 'Web',
				firstName: user?.firstName || '',
				email: user?.email || '',
				phone: user?.primary_phone || '',
				lastName: user?.lastName || '',
				address: user?.address || '',
        destinationDetail: user?.detail ?? "-",
				village: user?.village || '',
				district: user?.district || '',
				city: user?.city || '',
				state: user?.state || '',
				country: user?.country || '',
				latitude: user?.latitude || '',
				longitude: user?.longitude || '',
				billingaddress: cart?.store?.storeAddress,
				storeID: cart?.store?.storeID,
				items: cart?.item || [],
				deliveryPartner: values?.deliveryType,
				siteURL: `${process.env.REACT_APP_BASE_URL}payment/success`,
				failedURL: `${process.env.REACT_APP_BASE_URL}payment/failed`,
				paymentFee: values?.paymentType.fee || 0,
				paymentMethod: values?.paymentType.code || '',
				paymentGetway: 'SNAP',
				paymentReferenceID: values?.paymentReference || null,
				validateOrder: 'Yes',
				deliveryZone: '',
				packagingFee: paymentSummary?.packagingFee,
				platformFee: paymentSummary?.platformFee,
				deliveryFee: values?.deliveryFee,
				subsidy: values?.deliveryType?.subsidy || false,
				deliverySubsidies: values?.deliverySubsidies,
				token: user?.token,
				refresh_token: user?.refresh_token,
				b2b_voucher: b2bVoucher,
				my_voucher: myVoucher,
				pin: values?.pin ?? null,
			};
			console.log('dataPost: ', dataPost);

      sendAnalyticsCustomEvent("summary", dataPost);

			const config = {
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json',
				},
			};

			const urlDelivery = `deliverypartners/createPartnerOrder`;

			const orderDelivery = await axiosInstance.post(urlDelivery, JSON.stringify(dataPost), config);
			if (orderDelivery?.data?.status !== 'ok') {
				console.log(orderDelivery);
				showToast('error', orderDelivery?.data?.message || '', 1000);
				handleGetPathnerList();
				return setIsLoading(false);
			}

			dataPost.deliveryOrderID = orderDelivery?.data?.deliveryOrderID;

			const urlPayment = `paymentuvcr/processPayment`;

			const createPayment = await axiosInstance.post(urlPayment, JSON.stringify(dataPost), config);
			if (createPayment?.data?.status !== 'ok') {
				console.log(createPayment);
				showToast('error', createPayment?.data?.message || '', 1000);
				return setIsLoading(false);
			}

			if (values?.paymentType.code === 'ULTRA-VOUCHER') {
				console.log('go to payment success');
				history.push({
					pathname: '/payment/success',
					state: {
						payment: createPayment?.data?.data,
					},
				});
			} else {
				dispatch(
					setPayment({
						session_token: createPayment?.data?.session_token || null,
						data: createPayment?.data?.data,
						date: new Date(),
						timeLeft: 15 * 60,
						status: null,
					}),
				);

				history.push('/payment');
			}
			setIsLoading(false);
		} catch (error) {
			console.log('error: ', error);
			showToast('error', error?.message ?? '');
			setIsLoading(false);
		}
	};

	const handleVoucherDetail = (isShow, data) => {
		if (isShow) {
			setShowVoucherDetail({
				isOpen: true,
				data,
			});
		} else {
			setShowVoucherDetail({
				isOpen: false,
				data: null,
			});
		}
	};

	const groupMyForTooltip = (myVoucherList = []) => {
		if (!myVoucherList?.length) return [];
		console.log('groupMyForTooltip');
		const listUseMyVoucher = myVoucherList?.filter((voucher) => voucher.is_use) || [];

		const result = [];

		listUseMyVoucher?.forEach((voucher) => {
			const existingVoucher = result.find((v) => v.price === voucher.nominal_voucher);
			if (existingVoucher) {
				existingVoucher.total += 1;
			} else {
				result.push({
					name: voucher?.name,
					desc: 'Using the available voucher in my voucher.',
					total: 1,
					price: voucher?.nominal_voucher,
				});
			}
		});

		return result;
	};

	const groupB2BForTooltip = (b2bVoucherList = []) => {
		if (!b2bVoucherList?.length) return [];
		console.log('groupB2BForTooltip');
		const listUseB2BVoucher = b2bVoucherList?.filter((voucher) => voucher.total_is_use) || [];

		const result = [];

		listUseB2BVoucher?.forEach((voucher) => {
			const existingVoucher = result.find((v) => v.nominal === voucher.nominal);

			if (existingVoucher) {
				existingVoucher.total += voucher?.total_is_use;
			} else {
				result.push({
					name: voucher?.name,
					desc: 'Purchase voucher available at related merchants.',
					total: voucher?.total_is_use,
					nominal: voucher?.nominal,
				});
			}
		});

		return result;
	};

	const tooltipVoucherList = useMemo(() => {
		const groupMyVoucher = groupMyForTooltip(myVoucherList);
		const groupB2BVoucher = groupB2BForTooltip(b2bVoucherList);
		return [...groupMyVoucher, ...groupB2BVoucher];
	}, [myVoucherList, b2bVoucherList]);

	const tooltipOtherFee = useMemo(() => {
		const result = [];
		if (paymentSummary?.packagingFee) {
			result?.push({
				name: 'Packaging Fee',
				desc: 'Charge for packaging materials ensuring safe and hygienic food delivery.',
				total: null,
				nominal: paymentSummary?.packagingFee,
			});
		}
		if (paymentSummary?.platformFee) {
			result?.push({
				name: 'Platform Fee',
				desc: 'Fee for facilitating transactions via our integrated food delivery platform.',
				total: null,
				nominal: paymentSummary?.platformFee,
			});
		}
		if (paymentSummary?.paymentFee) {
			result?.push({
				name: 'Service Fee',
				desc: 'Service fee are applied to provide the best service for Ufood customers.',
				total: null,
				nominal: paymentSummary?.paymentFee,
			});
		}
		return result;
	}, [paymentSummary]);

	const strikeOutTotalPayment = useMemo(() => {
		return Number(paymentSummary?.subTotal) + Number(paymentSummary?.otherFee) + Number(paymentSummary?.deliveryFee);
	}, [paymentSummary?.subTotal, paymentSummary?.otherFee, paymentSummary?.deliveryFee]);

	const isNeedVerifyPinUltraValue = useMemo(() => {
		return values?.paymentType?.code === 'ULTRA-VOUCHER';
	}, [values?.paymentType?.code]);

	const isLowBalanceUltraValue = useMemo(() => {
		return Number(UVBalance?.value ?? 0) <= Number(paymentSummary?.totalPayment ?? 0);
	}, [UVBalance?.value, paymentSummary?.totalPayment]);

	const isDisableUltraValue = useMemo(() => {
		return isNeedVerifyPinUltraValue && isLowBalanceUltraValue;
	}, [isNeedVerifyPinUltraValue, isLowBalanceUltraValue]);

	const handleCloseBottomSheet = () => {
		console.log('handleCloseBottomSheet');
		setIsShowBottomSheet(false);
		setIsShowDelivery(false);
		setIsShowPaymentMethod(false);
		setIsShowSimpleProduct(false);
		setIsShowComboProduct(false);
		setIsShowVoucher(false);
		setIsShowMoreVoucher(false);
		setIsShowDeliveryLocation(false);
		setIsTooFar(false);
		setIsShowSetupPin(false);
		setIsShowTutor(false);
	};

	const handleShowTutor = () => {
		setIsShowTutor(true);
		setIsShowBottomSheet(false);
	};

	const handleDisplayVoucher = useMemo(() => {
		return (
			<div className='w-full h-full flex flex-col my-4'>
				<div className='mx-4 flex flex-col border-1 border-solid border-gray-200 rounded-xl'>
					<div className='flex flex-row justify-between relative min-h-16 h-fit items-center'>
						{isLoadingVoucherList ? (
							<div className='z-[1] w-full flex items-center justify-center'>
								<LoaderIcon />
							</div>
						) : (
							<div className='flex flex-row pl-2 items-center z-[1]'>
								<AutoVoucherIcon />
								<div className='pl-2 h-full'>
									{Boolean(maxNominalVoucher) ? <div className='text-white font-normal text-sm break-words'>Great, voucher was applied</div> : <div className='text-white font-normal text-sm break-words'>Add your voucher here</div>}
									{Boolean(totalSavedB2BVoucher) ? (
										<div className='text-white font-thin text-xxs pt-1 break-words'>Total voucher worth {formatRupiah(totalSavedB2BVoucher)}</div>
									) : Boolean(maxNominalVoucher) ? (
										<div className='text-white font-thin text-xxs pt-1 break-words'>Total voucher worth {formatRupiah(maxNominalVoucher)}</div>
									) : (
										<div className='text-white font-thin text-xxs pt-1 break-words'>Maximize discount with these vouchers now</div>
									)}
								</div>
							</div>
						)}
						{!isLoadingVoucherList ? (
							Boolean(totalSavedB2BVoucher) ? (
								<button
                  id='summary_apply_b2b_voucher'
									onClick={() => {
										handleApplyB2BVoucher();
									}}
									disabled={isLoading}
									className='bg-white px-4 py-2 mr-4 rounded-lg border-rose-violet text-rose-violet font-semibold min-w-fit'>
									Apply
								</button>
							) : totalNominalVoucher || totalNominalB2BVoucher ? (
								<CheckCircleIcon className={`h-7 w-7 z-[1] mr-4 text-green-600 `} />
							) : null
						) : null}
						<img src={`${isLoadingVoucherList ? './assets/img/bg-b2b-voucher.png' : totalNominalVoucher || totalNominalB2BVoucher ? './assets/img/bg-my-voucher.png' : './assets/img/bg-b2b-voucher.png'}`} className='h-full w-full absolute rounded-lg z-0' />
					</div>
					{!isLoadingVoucherList && (
						<div
              id='summary_view_voucher_menu'
							className='flex justify-between items-center my-4 mx-4'
							onClick={() => {
								setIsShowBottomSheet(true);
								setIsShowVoucher(true);
							}}>
							<div className='text-gray-600 font-semibold text-sm'>Check more vouchers</div>
							<div className='h-6 w-6 flex justify-end cursor-pointer'>
								<ArrowRightIcon class='h-6 w-6 text-gray-700' />
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}, [isLoadingVoucherList, maxNominalVoucher, totalSavedB2BVoucher]);

	return (
		<>
			{isLoading && <Loader overlay />}
			{isShowVerifyPinUltraValue && <VerifyPin onClose={handleCloseVerifyPinUltraValue} onSubmit={(pin, onValidate) => handleCheckPinUltraValue(pin, onValidate)} isInvalid={isInvalidPin} onClear={() => setIsInvalidPin(false)} />}
			{isShowTutor && <HowToSetPinUltraValue onBack={handleCloseBottomSheet} onSubmit={handleCloseBottomSheet} />}
			<div className={`${isShowVerifyPinUltraValue || isShowTutor ? 'hidden' : 'flex'} h-screen w-full justify-center items-center bg-white`}>
				<div className='relative h-screen w-full max-w-lg'>
					<div className={`absolute mt-5 w-full`}>
						<div className='flex flex-row justify-between mx-4'>
							<div className='flex justify-center items-center cursor-pointer' onClick={() => (isEmptyData(outlet?.storeSlug) ? history.goBack() : history.push(`/outlet/${outlet?.storeSlug}`))} id='summary_back'>
								<ArrowLeftIcon className='h-8 w-8 text-gray-900 p-1.5' />
								<div className='pl-2 text-base text-gray-900 font-semibold truncate max-w-72'>{cart?.store?.storeName ?? ''}</div>
							</div>
							<div className='flex gap-2'>
								<div className='flex justify-center items-center cursor-pointer' onClick={handleFavorite}>
									<HeartIcon className={`h-8 w-8 ${Boolean(Number(outlet?.favorite || outlet?.favourite)) ? 'fill-red-500 text-red-500' : 'text-gray-900'} p-1.5`} />
								</div>
							</div>
						</div>
						<div className='border-b-1 border-solid pb-2 border-gray-200' />
						<div className={`mx-4 mt-4 flex justify-between items-center`}>
							<div>
								<div className='text-xs text-gray-900 font-semibold'>Delivery Location</div>
								<div className='text-sm text-gray-900 font-semibold pt-2 max-w-64 overflow-ellipsis line-clamp-2'>{user?.address}</div>
							</div>
							<button
                id='summary_change_location'
								className={`${
									errors?.destinations?.address && touched?.destinations?.address ? 'border-red-500 text-red-500' : 'border-rose-violet text-rose-violet'
								} bg-white border-solid border-1 py-2 px-4 text-xs font-semibold rounded-md min-w-fit`}
								onClick={() => {
									setIsShowBottomSheet(true);
									setIsShowDeliveryLocation(true);
								}}>
								Change
							</button>
						</div>
						<div className='mx-4 mt-4'>
							<div className='flex items-center text-center'>
								<DoubleNoteIcon />
								<div className='text-xs text-gray-900 font-semibold pl-2'>Delivery Notes</div>
								<div className='text-xs text-gray-400 font-thin pl-2'>Optional</div>
							</div>
							<textarea
                id='summary_delivery_note'
								className='mt-2 py-2.5 px-4 text-xs placeholder:text-xs placeholder:text-gray-400 border-1 border-solid border-gray-300 bg-white h-auto'
								rows={1}
								defaultValue={values?.notes ?? ''}
								onChange={(e) => {
									setValues((prev) => {
										return {
											...prev,
											notes: e.target.value ?? '',
										};
									});
								}}
								placeholder='e.g. Please leave food at the door'
							/>
						</div>
						<div className='border-b-8 border-solid pb-4 border-gray-100' />
						<div className='mx-4 mt-4'>
							<div className='text-gray-900 text-xs font-semibold'>Delivery Option</div>
							<div className='mt-2'>
								<div className={`border-gray-300 border-1 border-solid rounded-md flex justify-between px-2 py-2.5`}>
									<div className='flex items-center'>
										<MotorbikeIcon />
										<div className='pl-2 text-gray-900 text-sm font-semibold'>{selectedPathner?.name ? `${selectedPathner?.name} ${selectedPathner?.serviceType}` : 'Choose Delivery'}</div>
									</div>
									<button
                    id='summary_choose_delivery'
										className={`${
											errors?.deliveryType && touched?.deliveryType ? 'border-red-500 text-red-500' : isLoadingDeliveryList ? 'border-gray-300 text-rose-violet' : 'border-rose-violet text-rose-violet'
										} bg-white border-solid border-1 py-2 px-4 text-xs font-semibold rounded-md min-w-[80px] flex justify-center items-center`}
										disabled={isLoadingDeliveryList}
										onClick={() => {
											setIsShowDelivery(true);
											setIsShowBottomSheet(true);
										}}>
										{isLoadingDeliveryList ? <LoaderIcon /> : selectedPathner?.name ? 'Change' : 'Choose'}
									</button>
								</div>
								{selectedPathner?.vehicle?.map((type, index) => {
									return (
										<div
											key={`type_${index}`}
											className={`border-1 border-gray-300 border-solid rounded-md flex justify-between px-2 py-2.5 mt-2 items-center ${type?.vehicleType === values?.deliveryType?.vehicleType ? '' : 'bg-gray-100'}`}
											onClick={() => {
                        sendAnalyticsEvent('Select Delivery Type', 'summary_select_delivery_type', type?.vehicleType)
												setValues((prev) => {
													return {
														...prev,
														deliveryType: type,
													};
												});
											}}>
											<div className=''>
												<div className={`text-sm capitalize ${type?.vehicleType === values?.deliveryType?.vehicleType ? 'text-gray-900' : 'text-gray-500'} font-semibold`}>{type?.vehicleType}</div>
												<div className='text-xs text-gray-400 pt-1 font-thin'>20-30 minute . Max 20 kg</div>
											</div>
											<div className='flex'>
												<div className='text-gray-500 font-normal text-sm line-through hidden'>Rp6.000</div>
												<div className={`pl-2 ${type?.vehicleType === values?.deliveryType?.vehicleType ? 'text-gray-900' : 'text-gray-500'} font-semibold text-sm`}>
													{formatRupiah((type?.amount || 0) + values?.deliveryFee)}
												</div>
												<div className='pl-2'>
													<input
														id='transport'
														type='radio'
														name='transport'
														value='bike1'
														checked={type?.vehicleType === values?.deliveryType?.vehicleType}
														onChange={() => null}
														className={`${type?.vehicleType === values?.deliveryType?.vehicleType ? '!border-rose-violet' : '!border-gray-400 !bg-gray-200'} after:!bg-rose-violet active:bg-white focus:bg-transparent`}
													/>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
						<div className='border-b-8 border-solid pb-4 border-gray-100' />
						<div className='mx-4 mt-4'>
							<div className='text-gray-900 text-sm font-semibold'>Order Summary</div>
							<div className='mt-4'>
								{cart?.item?.map((item, index) => {
									return <CartDetail key={index} data={item} index={index} handleGetProductDetail={handleGetProductDetail} handleCart={handleCart} />;
								})}
							</div>
						</div>
						<div className='border-b-1 border-solid pb-2 border-gray-200' />
						<div className='flex justify-between mx-4 my-4 items-center'>
							<div>
								<div className='text-sm text-gray-900 font-semibold'>Add More?</div>
								<div className='text-xs text-gray-500 pt-1 font-thin'>Need anything else? add other dishes</div>
							</div>
							<button id='summary_add_more_product' className='bg-white border-solid border-1 border-rose-violet py-1.5 px-6 text-xs font-semibold text-rose-violet rounded-md min-w-fit' onClick={() => history.push(`/outlet/${outlet?.storeSlug}`)}>
								Add
							</button>
						</div>
						<div className='border-b-1 border-solid pb-2 border-gray-200' />
						{handleDisplayVoucher}
						<div className='mx-4 mt-4'>
							<div className='text-gray-900 text-sm font-semibold'>Payment Method</div>
							<div
                id='summary_view_payment_methods'
								className={`mt-2 border-solid border-1 border-gray-300 rounded-lg px-4 py-2 flex justify-between items-center min-h-14 ${isDisableUltraValue && 'opacity-50'}`}
								onClick={() => {
									if (isLoadingPaymentMethodList || isLoadingUvBalance) return;
									setIsShowPaymentMethod(true);
									setIsShowBottomSheet(true);
								}}>
								{isLoadingPaymentMethodList || isLoadingUvBalance ? (
									<div className='flex justify-center items-center w-full h-full'>
										<LoaderIcon />
									</div>
								) : (
									<>
										<div className='flex items-center'>
											{isNeedVerifyPinUltraValue ? <UltraVoucherIcon /> : <img alt={values?.paymentType?.alt ?? ''} src={values?.paymentType?.image_url} className={values?.paymentType?.image_size ?? 'w-9 h-auto'} />}
											<div className='pl-2'>
												<div className='text-gray-700 text-xs font-thin'>{values?.paymentType?.name ?? ''}</div>
												{isNeedVerifyPinUltraValue && <div className='text-gray-900 text-sm font-semibold'>{formatRupiah(UVBalance?.value ?? 0)}</div>}
												{isDisableUltraValue && <div className={`text-xs pt-1 font-thin text-red-700`}>Your balance is insufficient</div>}
											</div>
										</div>
										<ChevronRightIcon className='h-4 w-4 text-gray-800' strokeWidth={3} />
									</>
								)}
							</div>
						</div>
						<div className='mx-4 mt-4 mb-28'>
							<div className='text-gray-900 text-sm font-semibold'>Payment Summary</div>
							<div className='mt-4 border-solid border-1 border-gray-300 rounded-lg px-4 py-2.5'>
								<div className={`flex justify-between items-center`}>
									<div className='text-gray-900 text-xs font-normal'>F&B Price</div>
									<div className='text-xs font-normal flex'>
										<div className='text-gray-700 pl-2'>{formatRupiah(paymentSummary?.subTotal || 0)}</div>
									</div>
								</div>
								<div className={`${!paymentSummary?.deliveryFee ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
									<div className='text-gray-900 text-xs font-normal'>Delivery</div>
									<div className='text-xs font-normal flex'>
										<div className='text-gray-400 font-thin line-through hidden'>Rp16.000</div>
										<div className='text-gray-700 pl-2'>{formatRupiah(paymentSummary?.deliveryFee || 0)}</div>
									</div>
								</div>
								<div className={`${!paymentSummary?.otherFee ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
									<div className='text-gray-900 text-xs font-normal flex items-center'>
										Other Fees
										<TooltipInfo title="What's Included?" items={tooltipOtherFee} id='summary_tooltip_other_fees' btn_id="summary_tooltip_other_fees_gotit" />
									</div>
									<div className='text-xs font-normal flex'>
										<div className='text-gray-700 pl-2'>{formatRupiah(paymentSummary?.otherFee || 0)}</div>
									</div>
								</div>
								<div className={`${!totalNominalB2BVoucher ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
									<div className='text-gray-900 text-xs font-normal'>Buy Voucher</div>
									<div className='text-xs font-normal flex'>
										<div className='text-gray-400 font-thin line-through'>{formatRupiah(totalNominalB2BVoucher)}</div>
										<div className='text-gray-700 pl-2'>{formatRupiah(totalPriceB2BVoucher)}</div>
									</div>
								</div>
								<div className={`${!paymentSummary?.myVoucherApplied ? 'hidden' : 'flex'} justify-between items-center mt-2`}>
									<div className='text-gray-900 text-xs font-normal flex items-center'>
										<TicketDiscountIcon className='h-6 w-6 mr-1' />
										Voucher Applied
										<TooltipInfo title='Voucher Applied' items={tooltipVoucherList} id="summary_tooltip_voucher_applied" btn_id="summary_tooltip_voucher_applied_gotit" />
									</div>
									<div className='text-xs font-normal flex'>
										<div className='text-rose-violet pl-2'>-{formatRupiah(paymentSummary?.myVoucherApplied || 0)}</div>
									</div>
								</div>
								<div className='border-b-1 border-solid pb-2 border-gray-300' />
								<div className='flex justify-between items-center mt-2'>
									<div className='text-gray-900 text-sm font-extrabold items-center'>Total Payment</div>
									<div className='text-sm font-extrabold flex'>
										<div className={`${!maxNominalVoucher && 'hidden'} text-gray-400 font-thin line-through`}>{formatRupiah(strikeOutTotalPayment)}</div>
										<div className='text-gray-900 pl-2'>{formatRupiah(paymentSummary?.totalPayment || 0)}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{isShowBottomSheet && (
						<BottomSheet
							open={isShowBottomSheet}
							onDismiss={handleCloseBottomSheet}
							snapPoints={({ minHeight, maxHeight }) => {
								// 544 932
								if (isShowDeliveryLocation) {
									return [minHeight * 0.8];
								}
								return [minHeight, maxHeight * 0.75];
							}}
              footer={isShowVoucher ? <button
                id='voucher_submit'
                className='w-full bg-rose-violet text-white flex justify-center font-normal text-sm border-0 items-center p-3 rounded-lg'
                onClick={() => {
                  handleCloseBottomSheet();
                }}>
                Submit
              </button> : null}>
							{isShowSimpleProduct && <ProductSimple product={cartItem} cart={cart} handleCart={handleCart} setCartItem={setCartItem} />}
							{isShowComboProduct && <ComboDetail product={product} item={item} handleSubmitItem={handleCart} isEdit={true} />}
							{isShowDelivery && (
								<div className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out rounded-t-xl ${isShowDelivery ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
									<div className='flex justify-center py-4 text-base text-gray-900 font-semibold'>Delivery Service</div>
									{pathnerList?.map((pathner, index) => {
										return (
											<div
												key={`pathner_${index}`}
												className={`border-1 border-gray-300 border-solid rounded-md flex justify-between px-2 py-2.5 mt-2 items-center`}
												onClick={() => {
													setSelectedPathner(pathner);
                          sendAnalyticsEvent('Select Delivery', 'summary_select_delivery', pathner?.name)
													if (pathner?.vehicle?.length === 1) {
														setValues((prev) => {
															return {
																...prev,
																deliveryType: pathner?.vehicle?.[0] || null,
															};
														});
													}
												}}>
												<div className=''>
													<div className={`text-sm capitalize text-gray-900 font-semibold`}>{`${pathner?.name} ${pathner?.serviceType}`}</div>
													<div className='text-xs text-gray-400 pt-1 font-thin'>Estimated arrival 30-40 min direct to you</div>
												</div>
												<div className='flex'>
													<div className='pl-2'>
														<input
															id='pathner'
															type='radio'
															name='pathner'
															value={pathner?.name}
															checked={selectedPathner?.name === pathner?.name}
															onChange={() => null}
															className={`${selectedPathner?.name === pathner?.name ? '!border-rose-violet' : '!border-gray-400'} after:!bg-rose-violet active:bg-white focus:ring-0 focus:bg-transparent`}
														/>
													</div>
												</div>
											</div>
										);
									})}
									<button
                    id='summary_confirm_delivery'
										className='mt-10 w-full bg-rose-violet text-white flex justify-center font-normal text-sm p-3 border-0 items-center'
										onClick={() => {
											setIsShowDelivery(false);
											setIsShowBottomSheet(false);
										}}>
										Got It
									</button>
								</div>
							)}
							{isShowPaymentMethod && (
								<div className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out rounded-t-xl ${isShowPaymentMethod ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
									<div className='flex justify-center py-4 text-base text-gray-900 font-semibold'>Select Payment Method</div>
									{paymentMethodList?.map((method, index) => {
										return (
											<div key={index} className='w-full'>
												<div className='flex justify-start py-4 text-sm text-gray-800 font-semibold capitalize'>{method?.category ?? ''}</div>
												{method?.payment_method?.map((payment, index) => {
													const isDisabled = payment?.code === 'ULTRA-VOUCHER' && isLoadingCheckPinUltraValue;
													const isLowBalance = payment?.code === 'ULTRA-VOUCHER' && isLowBalanceUltraValue;
													return (
														<div
                              id={payment?.id}
															key={`payment_${index}`}
															className={`border-1 border-gray-300 border-solid rounded-md flex justify-between px-2 py-2.5 mt-2 items-center min-h-14 ${(isDisabled || isLowBalance) && 'opacity-50'}`}
															onClick={() => {
																if (isDisabled || isLowBalance) return;
																setValues((prev) => {
																	return {
																		...prev,
																		paymentType: payment,
																	};
																});
															}}>
															<div className='flex items-center'>
																{payment?.code === 'ULTRA-VOUCHER' ? <UltraVoucherIcon /> : <img alt={payment?.alt ?? ''} src={payment?.image_url} className={payment?.image_size || 'w-9 h-auto'} />}
																<div className='pl-2'>
																	<div className={`text-xs capitalize font-semibold text-gray-900`}>{`${payment?.name}`}</div>
																	{payment?.code === 'ULTRA-VOUCHER' && (
																		<>
																			{isLoadingCheckPinUltraValue ? (
																				<LoaderIcon />
																			) : (
																				<div className={`text-xs pt-1 font-thin ${isDisabled ? 'text-red-700' : 'text-gray-700'}`}>
																					{isDisabled ? `Ultra Value is currently unavailable.` : `Balance ${formatRupiah(UVBalance?.value || 0)}`}
																				</div>
																			)}
																			{isLowBalanceUltraValue && <div className={`text-xs pt-1 font-thin text-red-700`}>Your balance is insufficient</div>}
																		</>
																	)}
																</div>
															</div>
															<div className='flex'>
																<div className='pl-2'>
																	<input
																		id='payment'
																		type='radio'
																		name='payment'
																		value={payment?.code}
																		checked={values?.paymentType?.code === payment?.code}
																		onChange={() => null}
																		disabled={isDisabled || isLowBalance}
																		className={`${values?.paymentType?.code === payment?.code ? '!border-rose-violet' : '!border-gray-400'} after:!bg-rose-violet active:bg-white focus:bg-transparent`}
																	/>
																</div>
															</div>
														</div>
													);
												})}
											</div>
										);
									})}
									<button
                    id='payment_methods_choose'
										className='mt-10 w-full bg-rose-violet text-white flex justify-center font-normal text-sm p-3 border-0 items-center'
										onClick={() => {
											setIsShowPaymentMethod(false);
											setIsShowBottomSheet(false);
										}}>
										Choose Payment
									</button>
								</div>
							)}
							{isShowVoucher && (
								<>
									<div className={`bg-white transition-transform duration-1000 ease-in-out rounded-t-xl h-full relative ${isShowVoucher ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
										<div className='flex justify-start py-4 text-base text-gray-900 font-semibold mx-4'>Add Voucher</div>
										<div className='border-b-1 border-solid border-gray-200 mb-4' />
										<div className='h-full overflow-y-scroll overflow-x-hidden scrollbar-hide '>
											<div className={`mt-4 h-full`}>
												<div className='text-base font-semibold text-gray-900 mx-4'>My Voucher</div>
												{myVoucherList?.length ? (
													<>
														{!isShowMoreVoucher
															? myVoucherList?.slice(0, 3)?.map((voucher, index) => {
																	const isDisabled = Number(voucher?.nominal_voucher) > Number(remainingNominal);
																	return (
																		<Voucher
																			key={`my_voucher_all_${index}`}
																			showLabelCount={false}
																			type={'my_voucher'}
																			data={voucher}
																			handleUseVoucher={(data) => handleUpdateVoucherList(data.id, 'my_voucher', data.is_use ?? false)}
																			isDisabled={isDisabled}
																			onOpenDetail={() => handleVoucherDetail(true, voucher)}
																		/>
																	);
															  })
															: myVoucherList?.map((voucher, index) => {
																	const isDisabled = Number(voucher?.nominal_voucher) > Number(remainingNominal);
																	return (
																		<Voucher
																			key={`my_voucher_all_${index}`}
																			showLabelCount={false}
																			type={'my_voucher'}
																			data={voucher}
																			handleUseVoucher={(data) => handleUpdateVoucherList(data.id, 'my_voucher', data.is_use ?? false)}
																			isDisabled={isDisabled}
																			onOpenDetail={() => handleVoucherDetail(true, voucher)}
																		/>
																	);
															  })}
														<div className={`${myVoucherList?.length <= 3 && 'hidden'}`}>
															{!isShowMoreVoucher ? (
																<div
																	className={`flex mt-6 justify-center items-center text-green-600 text-sm font-semibold w-full`}
																	onClick={() => {
																		console.log('onClick more voucher');
																		setIsShowMoreVoucher(true);
																	}}>
																	See More
																	<ChevronDownIcon class='h-5 w-5 text-green-600' strokeWidth={3} />
																</div>
															) : (
																<div
																	className={`flex mt-6 justify-center items-center text-green-600 text-sm font-semibold w-full`}
																	onClick={() => {
																		console.log('onClick more voucher');
																		setIsShowMoreVoucher(false);
																	}}>
																	See Less
																	<ChevronUpIcon class='h-5 w-5 text-green-600' strokeWidth={3} />
																</div>
															)}
														</div>
													</>
												) : (
													<div className='my-8 flex flex-col items-center'>
														<div className='text-gray-900 font-semibold text-base'>You don't have any voucher</div>
														<div className='text-gray-900 font-thin text-xs'>Don’t miss out! Purchase vouchers for discounts</div>
													</div>
												)}
												<div className='border-b-8 border-solid pb-5 border-gray-200 w-screen' />
												<div className={`${b2bVoucherList?.length ? 'mb-5' : ''}`}>
													<div className='text-base font-semibold text-gray-900 mt-6 mx-4'>More Voucher from Ufood</div>
													{b2bVoucherList?.length ? (
														b2bVoucherList?.map((voucher, index) => {
															const isDisabled = Number(voucher?.nominal) > Number(remainingNominal);
															return (
																<Voucher
																	key={`b2b_voucher_all_${index}`}
																	showLabelCount={false}
																	type={'b2b_voucher'}
																	data={voucher}
																	handleUseVoucher={(data, type) => handleUpdateVoucherList(data.code, 'b2b_voucher', data.is_use ?? false, type)}
																	isDisabled={isDisabled}
																	onOpenDetail={() => handleVoucherDetail(true, voucher)}
																/>
															);
														})
													) : (
														<div className='my-8 flex flex-col items-center'>
															<div className='text-gray-900 font-semibold text-base'>You don't have any voucher</div>
															<div className='text-gray-900 font-thin text-xs'>Don’t miss out! Purchase vouchers for discounts</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									{showVoucherDetail.isOpen && <VoucherDetail data={showVoucherDetail.data} onClose={() => handleVoucherDetail(false)} />}
								</>
							)}
							{isShowDeliveryLocation && <DeliveryLocationDetail handleCloseDelivery={handleCloseBottomSheet} back='summary' />}
							{isTooFar && <TooFar show={isTooFar} handleClickButton={handleCloseBottomSheet} />}
							{isShowSetupPin && <SetPinUltraValue onSubmit={handleShowTutor} />}
						</BottomSheet>
					)}
					{cart && !isShowBottomSheet && (
						<Footer position='fixed' id='cart'>
							<div className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out ${cart ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
								<div className='items-center hidden'>
									<DiscountIcon />
									<div className='pl-2 text-gray-900 text-xs'>Yay, you’re saving 10k. Add more and save up to 50k!</div>
								</div>
								<button
                  id='summary_pay_and_order'
									type='button'
									disabled={isLoading || isLoadingDeliveryList || isLoadingVoucherList || isLoadingPaymentMethodList || isDisableUltraValue || !paymentSummary.totalPayment}
									className={`${
										isLoading || isLoadingDeliveryList || isLoadingVoucherList || isLoadingPaymentMethodList || isDisableUltraValue || !paymentSummary.totalPayment ? 'bg-gray-300 text-white' : 'bg-rose-violet text-white'
									} mt-2 w-full flex justify-between font-normal text-sm p-3 items-center border-0`}
									onClick={() => handleSubmit()}>
									<div>Pay and Order</div>
									<div>{formatRupiah(parseInt(paymentSummary?.totalPayment || 0))}</div>
								</button>
							</div>
						</Footer>
					)}
				</div>
			</div>
		</>
	);
};

export default Summary;
