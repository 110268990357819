export const centerLocation = {
	lat: -1.2378167595291314,
	lng: 114.02678743956167,
};

const ultraVoucher = {
  id: 'payment_methods_select_ultravalue',
	code: 'ULTRA-VOUCHER',
	name: 'Ultra Value',
	minimum_amount: 1,
	maximum_amount: 10000000,
	status: 'ACTIVE',
	fee: 0,
	image_url: 'https://uvdev.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2019-12-23T03%3A16%3A35.175Z1577070994-group.jpeg',
	image_size: '',
	image_size_payment: 'w-10 h-auto',
};

const vaBni = {
  id: 'payment_methods_select_va_bni',
	code: 'VABNI',
	name: 'Virtual Account BNI',
	minimum_amount: 10000,
	maximum_amount: 100000000,
	status: 'ACTIVE',
	fee: 2000,
	image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A40%3A55.520Z1662086455-BNI.png',
	image_size: 'w-9 h-auto',
	image_size_payment: 'w-14 h-auto',
};

const vaBca = {
  id: 'payment_methods_select_va_bca',
	code: 'VADIRECTBCA',
	name: 'Virtual Account BCA',
	minimum_amount: 15000,
	maximum_amount: 9000000,
	status: 'ACTIVE',
	fee: 2000,
	image_url: 'https://uvdev.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2019-09-30T03%3A39%3A05.606Z1569814745-group.jpeg',
	image_size: 'w-9 h-auto',
	image_size_payment: 'w-16 h-auto',
};
const vaMandiri = {
  id: 'payment_methods_select_va_mandiri',
	code: 'VAMANDIRI',
	name: 'Virtual Account Mandiri',
	minimum_amount: 10000,
	maximum_amount: 100000000,
	status: 'ACTIVE',
	fee: 1500,
	image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2023-07-11T11%3A21%3A41.987Z1689074501-group.png',
	image_size: 'w-12 h-auto',
	image_size_payment: 'w-20 h-auto',
};
const vaBri = {
  id: 'payment_methods_select_va_bri',
	code: 'VABRI',
	name: 'Virtual Account BRI',
	minimum_amount: 10000,
	maximum_amount: 100000000,
	status: 'ACTIVE',
	fee: 2000,
	image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A39%3A37.034Z1662086376-BRI.png',
	image_size: 'w-7 h-auto',
	image_size_payment: 'w-11 h-auto',
};
const vaPermata = {
  id: 'payment_methods_select_va_permata',
	code: 'VAPERMATA',
	name: 'Virtual Account PERMATA',
	minimum_amount: 10000,
	maximum_amount: 100000000,
	status: 'ACTIVE',
	fee: 2000,
	image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A36%3A49.642Z1662086209-Permata.png',
	image_size: 'w-20 h-auto',
	image_size_payment: 'w-28 h-auto',
};

export const listPaymentMethod = [
	{
		id: 2,
		category: 'Payment Method',
		image_url: '',
		payment_method: [ultraVoucher],
	},
	{
		id: 4,
		category: 'Other Payment Method',
		image_url: '',
		payment_method: [vaBni, vaBca, vaMandiri, vaBri, vaPermata],
	},
];

export const OrderStatusList = {
	1: { message: 'Waiting For Merchant Confirmation', className: 'sr-process' },
	2: { message: 'On Progress', className: 'sr-process' },
	3: { message: 'On Progress', className: 'sr-process' },
	4: { message: 'Completed', className: 'sr-completed' },
	5: { message: 'Canceled', className: 'sr-cancel' },
	6: { message: 'Waiting Payment', className: 'sr-waiting-payment' },
};

export const ListPaymentMethodAll = [ultraVoucher, vaBni, vaBca, vaMandiri, vaBri, vaPermata];

export const uniqueID = process.env.REACT_APP_UNIQUE_ID;
export const apiUrl = process.env.REACT_APP_API_URL;
export const mainUrl = process.env.REACT_APP_SITE_MAIN_URL;
export const voucherUrl = process.env.REACT_APP_VOUCHER_URL;
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const deliveryId = process.env.REACT_APP_DELIVERY_ID;
export const pickupId = process.env.REACT_APP_PICKUP_ID;
export const CountryName = "Indonesia";
export const productNoImg = "/img/product-noimg.jpg";
export const companyName = "UV";
export const currency = "RP";
export const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const publicImageUrl = process.env.REACT_APP_PUBLIC_IMAGE_URL;
export const privateImageUrl = process.env.REACT_APP_PRIVATE_IMAGE_URL;
export const fcmToken = process.env.REACT_APP_FCMTOKEN;
export const googleAnalytics = process.env.REACT_APP_ANALYTIC;

export const HZNDeliveryStatus = "" | "NEW ORDER" | "ALLOCATING" | "REJECTED" | "DRIVER ASSIGNED" | "PICKING UP" | "DRIVER NOT FOUND" | "ITEM PICKED" | "ON DELIVERY" | "RECEIVED" | "COMPLETED" | "REACTIVATED" | "ON HOLD" | "CANCELLED" | "DELAYED" | "EXPIRED" | "RETURNED" | "FAILED";
export const listStatusLookingForDriver = ["ALLOCATING", "REJECTED", "CANCELLED", "DRIVER NOT FOUND"];
export const listStatusDriverExist = ["DRIVER ASSIGNED", "PICKING UP", "ITEM PICKED", "ON DELIVERY", "RECEIVED", "COMPLETED"];

export const maxLengthNotes = 255;

// timeout untuk api dan load image
export const timeout = 10 * 1000;
export const timeoutImage = 20 * 1000;