import React from 'react';
import { Link } from "react-router-dom";
import barrow from "../../../common/images/arrow-left.svg";



function Header() {
  return (
    <div className='flex items-center px-4 h-[53px] sticky top-0 z-10 bg-white' style={{ borderBottom: "2px solid #D3D3D3" }}>
      <Link to="/" id="order_history_back">
        <img src={barrow} alt="Back" className="w-6 h-6" />
      </Link>
      <h2 className="text-base font-semibold ml-2">Order History</h2>
    </div>
  );
}

export default Header;
