import React from 'react';
import searchIcon from '../../../common/images/revamp/icon/search.svg';
import closeCircleIcon from '../../../common/images/revamp/icon/close-circle.svg';
import mapIcon from '../../../common/images/revamp/icon/map.svg';
import gpsIcon from '../../../common/images/revamp/icon/gps.svg';
import DeliverySavedAddress from './SavedAddress';
import DeliveryRecent from './Recent';
import DeliverySearchResult from './SearchResult';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { sendAnalyticsEvent, sendAnalyticsPageView } from '../../../utils/helper';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { setLocation } from '../../../store/reducers/location';
import { setUser } from '../../../store/reducers/user';

const DeliveryLocationDetail = (props) => {
	/*======================== Props ======================== */

	const { handleCloseDelivery, back } = props;
	const {
		ready: isAutocompleteReady,
		value: search,
		suggestions: { loading, data: searchData },
		setValue: setSearch,
		clearSuggestions,
	} = usePlacesAutocomplete({
		debounce: 500,
		requestOptions: { types: [] },
	});

	/*======================== Store ======================== */

	const dispatch = useDispatch();
	const { savedIsOnSearch, recentData } = useSelector((state) => state.location);
	const address = useSelector((store) => store?.user?.address || '');

	/*======================== Handler ======================== */

	const handleFormatingAddress = ({ lat, lng, formatted_address, address_components }) => {
		let country = '';
		let state = '';
		let city = '';
		let village = '';
		let district = '';
		let postalCode = '';

		address_components?.forEach((item) => {
			if (item.types.includes('country')) {
				country = item.long_name;
			}
			if (item.types.includes('administrative_area_level_1')) {
				state = item.long_name;
			}
			if (item.types.includes('administrative_area_level_2')) {
				city = item.long_name;
			}
			if (item.types.includes('administrative_area_level_3')) {
				village = item.long_name;
			}
			if (item.types.includes('administrative_area_level_4')) {
				district = item.long_name;
			}
			if (item.types.includes('postal_code')) {
				postalCode = item.long_name;
			}
		});
		dispatch(
			setUser({
				address: formatted_address,
				latitude: lat,
				longitude: lng,
				country,
				state,
				city,
				village,
				district,
				postalCode,
			}),
		);
		setSearch('');
		handleCloseDelivery();
	};

	const handleSelectLocation = (location) => {
		getGeocode({ placeId: location.place_id }).then((results) => {
			const result = results[0];
			const { lat, lng } = getLatLng(result);
			if (savedIsOnSearch) {
				setSearch('');
				dispatch(
					setLocation({
						savedIsOpen: true,
						savedIsOnSearch: false,
						savedSearchResult: {
							lat,
							lng,
						},
					}),
				);
			} else {
				handleFormatingAddress({
					lat,
					lng,
					formatted_address: result?.formatted_address || '',
					address_components: result?.address_components || {},
				});
			}
		});
	};

	const handleCancelSearchSaved = () => {
		dispatch(
			setLocation({
				savedIsOpen: true,
				savedIsOnSearch: false,
				savedSearchResult: null,
			}),
		);
	};

	useEffect(() => {
		sendAnalyticsPageView('search_address_session');
	}, []);

	useEffect(() => {
		sendAnalyticsEvent('Select Location', 'homepage_address', address);
	}, []);

	return (
		<div className='w-full h-full overflow-y-auto'>
			<div className='p-4'>
				<div className='flex items-center rounded-full border border-solid border-grayD3 p-2.5 gap-3 mb-2.5'>
					<img src={searchIcon} alt='search' />
					<input
						id='search_address_select_location'
						placeholder='Search location'
						name='search'
						className='flex-auto w-full m-0 bg-transparent border-none outline-none'
						value={search}
						onChange={(event) => {
							setSearch(event.target.value);
						}}
						disabled={!isAutocompleteReady}
					/>
					{search && (
						<img
							src={closeCircleIcon}
							alt='clear-search'
							onClick={() => {
								setSearch('');
								clearSuggestions();
							}}
						/>
					)}
				</div>
				<div className='w-full flex justify-end  mb-4'>
					{savedIsOnSearch ? (
						<div className='inline-flex gap-1' onClick={() => handleCancelSearchSaved()}>
							<img src={mapIcon} alt='map' />
							<span className='text-blue text-xs'>Select on map</span>
						</div>
					) : (
						<Link id='search_address_select_map' to={back ? `/google-map?back=${back}` : '/google-map'} className='inline-flex gap-1 items-center'>
							<img src={mapIcon} alt='map' />
							<span className='text-blue text-xs'>Select on map</span>
						</Link>
					)}
				</div>

				{!savedIsOnSearch && !search && (
					<div className='flex gap-2 5 items-center' onClick={handleCloseDelivery}>
						<img src={gpsIcon} alt='gps' />
						<div className='grow space-y-0.5'>
							<p className='text-black37 text-sm font-semibold'>Current Location</p>
							<p className='text-gray90 text-xxs'>{address}</p>
						</div>
					</div>
				)}
			</div>

			<div className='h-2 w-full bg-grayf4 mb-4'></div>

			<div className='space-y-4 mb-4' style={{ width: '100%' }}>
				{search || savedIsOnSearch ? (
					<DeliverySearchResult {...{ search, searchData, loading, handleSelectLocation }} />
				) : (
					<>
						<DeliverySavedAddress {...{ handleCloseDelivery }} />

						{recentData && recentData.length > 0 && <DeliveryRecent {...{ handleCloseDelivery }} />}
					</>
				)}
			</div>
		</div>
	);
};

export default DeliveryLocationDetail;
