import locationIcon from '../../../../common/images/revamp/icon/location.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentList } from '../../../../store/reducers/location';
import { useEffect } from 'react';
import { setUser } from '../../../../store/reducers/user';
import { sendAnalyticsCustomEvent } from '../../../../utils/helper';

const DeliveryRecent = (props) => {
	/*======================== Props ======================== */

	const { handleCloseDelivery } = props;

	/*======================== Store ======================== */

	const dispatch = useDispatch();
	const user_id = useSelector((state) => state.user?.customerID || '');
	const { recentData: recentLocation, recentLoading: loading } = useSelector((state) => state.location);

	/*======================== Handler ======================== */

	const handleSelectLocation = (location) => {
		console.log('handleSelectLocation: ', location);
		const { address, latitude, longitude, country, state, city, village, district, postal_code } = location;

		dispatch(
			setUser({
				address,
				latitude,
				longitude,
				country,
				state,
				city,
				village,
				district,
				postalCode: postal_code,
			}),
		);
		handleCloseDelivery();
	};

	/*======================== UseEffect ======================== */

	useEffect(() => {
		dispatch(getRecentList(user_id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='px-4 h-80'>
			<p className='text-black37 font-semibold text-sm mb-4'>Recent</p>
			<div className='space-y-2.5'>
				{recentLocation?.map((location, index) => (
					<div key={index} id='search_address_recent_location' className='[&:not(:last-child)]:border-b border-solid border-grayD3 p-2.5 flex items-center gap-2.5' onClick={() => handleSelectLocation(location)}>
						<img src={locationIcon} alt='location' className='w-4.5 h-4.5' />
						<div className='space-y-0.5'>
							<p className='text-gray90 text-xxs'>{location.address}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default DeliveryRecent;
