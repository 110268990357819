import React, { useEffect, useMemo, useState } from 'react';
import cardlessWithdrawal from '../../common/images/revamp/images/cardless-withdrawal.png';
import { useHistory, useLocation } from 'react-router';
import { uniqueID } from '../../utils/constants';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { axiosInstance, encodeValue, formatRupiah, sendAnalyticsPageView } from '../../utils/helper';
import Loader from '../../components/Loader';
import { ListPaymentMethodAll } from '../../utils/constants';
import { UltraVoucherIcon } from '../../utils/svg';

const Success = () => {
	const history = useHistory();
	const location = useLocation();
	const { payment } = location.state;

	const user = useSelector((store) => store?.user);
	const [isLoading, setIsLoading] = useState(false);
	const [orderNumber, setOrderNumber] = useState(null);

	const handleGetOrderId = async () => {
		if (!payment?.order_id) return;
		console.log('handleGetOrderId');
		try {
			setIsLoading(true);
			const postData = {
				paymentReferenceID: payment?.order_id,
			};
			const res = await axiosInstance.post(`get-order-number`, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				const orderNumber = res?.data?.data?.orderNumber;
				setOrderNumber(orderNumber);
			}
			setIsLoading(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoading(false);
		}
	};

  useEffect(() => {
    sendAnalyticsPageView("payment_success_session");
  }, []);

	useEffect(() => {
		handleGetOrderId();
	}, [payment?.order_id]);

	const handleRemoveCart = async () => {
		console.log('handleRemoveCart');
		try {
			const postData = {
				unquieid: uniqueID,
				customerID: encodeValue(user?.customerID),
			};
			const res = await axiosInstance.post(`cart/removeCart`, qs.stringify(postData));
			return res;
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleOrderAgain = async (item) => {
		console.log('handleOrderAgain');
		try {
			const removeCart = await handleRemoveCart();
			console.log('removeCart: ', removeCart);
			if (removeCart?.data?.status !== 'ok') {
				return;
			}
			const postData = {
				unquieid: uniqueID,
				customerID: encodeValue(user?.customerID),
				orderID: encodeValue(orderNumber),
			};
			const res = await axiosInstance.post(`orders/orderAgain`, qs.stringify(postData));
			console.log('res: ', res);
			if (res?.data?.status === 'ok') {
				history.push('/summary');
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleBackToHome = () => {
		history.push('/');
	};

	const handleCheckOrder = () => {
		history.push(`/history/on-process/${orderNumber}`);
	};

	const paymentMethod = useMemo(() => {
		if (!ListPaymentMethodAll?.length || !payment) return '';
		return ListPaymentMethodAll?.find((paymentMethod) => {
			const paymentCode = paymentMethod?.code;
			return paymentCode === payment?.payment_category?.[0]?.payment_method?.[0]?.code || paymentCode === payment?.payment_category?.payment_method?.[0]?.code;
		});
	}, [ListPaymentMethodAll, payment]);

	return (
		<>
			{isLoading && <Loader overlay />}
			<div className='h-screen w-full flex justify-center items-center bg-white'>
				<div className='relative h-screen w-full max-w-lg flex items-center'>
					<div className='flex flex-col justify-center items-center w-full mx-4'>
						<img src={cardlessWithdrawal} alt='Success Icon' className='w-44 h-auto object-contain object-center' />
						<div className='text-xl font-bold text-gray-600 text-center'>
							Congratulations,
							<br />
							your payment was successful
						</div>
						<div className='text-sm text-gray-400 text-center mt-4'>
							Your payment has been successfully verified.
							<br />
							Thank you for shopping at UFood
						</div>
						<div className='mt-5 w-full border border-gray-300 border-solid rounded-lg p-4'>
							<div className='flex justify-between items-center'>
								<div className='flex flex-col items-center'>
									<span className='text-sm text-gray-400'>Total Payment</span>
									<div className='text-lg font-semibold text-gray-600'>{formatRupiah(payment?.total_amount ?? 0)}</div>
								</div>
								<button
                  id='payment_success_view_detail'
									disabled={!orderNumber}
									onClick={handleCheckOrder}
									className={`text-sm border-1 border-solid ${
										!orderNumber ? 'bg-gray-400 border-gray-400 text-white' : 'bg-white border-rose-violet text-rose-violet'
									} px-4 py-1.5 rounded-md font-semibold`}>
									Check Order
								</button>
							</div>
							<div className='border-b-1 border-solid pb-2 border-gray-200 my-2 -mx-4' />
							<div className='text-sm text-gray-400 pt-2'>Payment Method</div>
							<div className='flex items-center mt-2'>
								{paymentMethod?.code === 'ULTRA-VOUCHER' ? (
									<UltraVoucherIcon />
								) : (
									<img src={paymentMethod.image_url} alt={paymentMethod.code} className={`${paymentMethod.image_size_payment ?? 'w-12'} h-auto`} />
								)}
								<div className='pl-2 text-base font-semibold text-gray-700'>{paymentMethod?.name ?? ''}</div>
							</div>
						</div>
						<button
              id='payment_success_reorder'
							disabled={!orderNumber}
							onClick={handleOrderAgain}
							className={`mt-5 w-full py-2.5 rounded-lg text-white text-base font-semibold ${!orderNumber ? 'bg-gray-400 border-gray-400' : 'bg-rose-violet'} `}>
							Order Again
						</button>
						<button onClick={handleBackToHome} className='mt-4 w-full py-2.5 rounded-lg border-1 border-solid border-rose-violet bg-white text-base font-semibold text-rose-violet' id='payment_success_back_to_home'>
							Back to Home
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Success;
