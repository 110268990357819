import React from 'react';
import { formatRupiah, isEmptyData, sendAnalyticsEvent } from '../../utils/helper';
import { PlusIcon, MinusIcon, InformationCircleIcon, ClockIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { formatDate } from '../../utils/helper.js';
import ImageLoader from '../ImageLoader';

const Voucher = ({ data = {}, handleUseVoucher = (data) => {}, type = 'my_voucher', showLabelCount = false, isDisabled = false, onOpenDetail = () => {} }) => {

	return (
		<div key={`${type}_${data?.id}`} className='h-52 w-full relative mt-4'>
			{showLabelCount && (
				<div className='z-20 relative top-1 -left-2'>
					<img src='./assets/img/label-voucher.png' className='h-auto w-20 object-fill absolute shadow-custom bg-transparent rounded-lg' />
					<div className='text-white text-xs z-20 absolute h-7 items-center flex pl-2'>{data?.total_voucher || 0} voucher</div>
				</div>
			)}
			<img src='./assets/img/bg-voucher-list.png' className='h-52 w-full object-fill absolute z-0 rounded-lg top-0' />
			<div className='relative z-10 px-7 py-6 flex flex-col justify-between '>
				<div className='flex'>
					<ImageLoader src={data?.image?.url || data?.image} alt={data?.image?.url || data?.image} className='h-20 w-20 rounded-xl object-cover object-center border-1 border-solid border-gray-100 bg-gray-200' />
					<div className={`pl-3 w-full h-full ${!isEmptyData(data?.expired_date) ? 'mb-[3.5rem]' : 'mb-20'}`}>
						<div className='flex justify-between items-center mr-20 md:mr-0'>
							<div className='text-gray-500 text-sm font-semibold truncate'>{data?.name}</div>
							<InformationCircleIcon class='h-5 min-h-5 w-5 min-w-5 text-gray-400' onClick={onOpenDetail} id={type === 'my_voucher' ? 'voucher_tooltip_myvoucher' : 'voucher_tooltip_b2b' } />
						</div>
						<div className='flex mt-1 items-center'>
							<div className={`text-gray-500 text-xs font-normal ${type !== 'my_voucher' && 'hidden'}`}>Total Nominal:</div>
							<div className='pl-1 text-rose-violet text-base font-semibold'>{formatRupiah(data?.nominal_voucher || data?.price_user || 0)}</div>
						</div>
						<div className={`${isEmptyData(data?.expired_date) ? 'hidden' : 'flex'} mt-1 items-center`}>
							<ClockIcon class='h-5 w-5 text-red-500' />
							<div className='text-gray-500 text-xs font-semibold pl-1'>Exp Date :</div>
							<div className='text-gray-500 text-xs font-semibold pl-1'>{formatDate(data?.expired_date, 'DD MMM YYYY')}</div>
						</div>
					</div>
				</div>
				{type === 'my_voucher' ? (
					<div className={`flex items-center ${data.is_use ? 'justify-between' : 'justify-end'} w-full`}>
						<div className={data.is_use ? 'flex items-center' : 'hidden'}>
							<CheckCircleIcon class='h-5 w-5 text-rose-violet' />
							<div className='pl-1 text-gray-600 text-sm font-semibold'>Applied</div>
						</div>
						<button
              id={data.is_use ? 'voucher_add_myvoucher' : 'voucher_remove_myvoucher'}
							disabled={data.is_use ? false : isDisabled ? isDisabled : false}
							onClick={() => {
                sendAnalyticsEvent(data.is_use ? 'Click Add My Voucher' : 'Click Remove My Voucher', data.is_use ? 'voucher_add_myvoucher' : 'voucher_remove_myvoucher', `${data?.name}-${data?.nominal_voucher || data?.price_user}`)
                handleUseVoucher(data);
              }}
							className={`py-2 px-8 ${data.is_use ? 'text-red-500 border-red-500' : isDisabled ? 'text-gray-300 border-gray-300' : 'text-rose-violet border-rose-violet'} font-semibold text-sm`}>
							{data.is_use ? 'Remove' : 'Use'}
						</button>
					</div>
				) : (
					<div className={`flex items-center justify-end w-full`}>
						<div className='absolute bottom-0'>
							{Number(data?.total_is_use) ? (
								<div className='bg-white p-1 rounded-md cursor-pointer flex items-center border-solid border-1 border-byzantium'>
									<MinusIcon 
                    className='h-5 w-5 text-byzantium' 
                    onClick={() => {
                      sendAnalyticsEvent(Number(data?.total_is_use) === 1 ? 'voucher_remove_b2b' : 'Click Minus B2B Voucher', Number(data?.total_is_use) === 1 ? 'voucher_remove_b2b' : 'voucher_decrease_b2b', `${data?.name}-${data?.nominal_voucher || data?.price_user}`)
                      handleUseVoucher(data, 'minus');
                    }} 
                    id='voucher_decrease_b2b' />
									<input type='text' className='!bg-white !m-0 !p-0 !h-5 !w-8 text-center !text-gray-500 font-semibold text-xs' value={Number(data?.total_is_use || 0)} disabled />
									<PlusIcon
                    id='voucher_increase_b2b'
										className={`h-5 w-5 ${isDisabled ? 'text-gray-400' : 'text-byzantium'}`}
										onClick={() => {
											if (isDisabled) return;
                      sendAnalyticsEvent('Click Plus B2B Voucher', 'voucher_increase_b2b', `${data?.name}-${data?.nominal_voucher || data?.price_user}`)
											handleUseVoucher(data, 'plus');
										}}
									/>
								</div>
							) : (
								<button
                  id='voucher_add_b2b'
									disabled={data.total_is_use ? false : isDisabled ? isDisabled : false}
									onClick={() => {
                    sendAnalyticsEvent('Click Add B2B Voucher', 'voucher_add_b2b', `${data?.name}-${data?.nominal_voucher || data?.price_user}`)
                    handleUseVoucher(data, 'plus');
                  }}
									className={`py-2 px-8 ${isDisabled ? 'text-gray-300 border-gray-300' : 'text-rose-violet border-rose-violet'} font-semibold text-sm`}>
									Add
								</button>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Voucher;
