import locationIcon from '../../../../common/images/revamp/icon/location.svg';
import notFoundImage from '../../../../common/images/revamp/images/search-not-found.svg';

const DeliverySearchResult = (props) => {
	/*======================== Props ======================== */

	const { search, loading, handleSelectLocation, searchData } = props;

	/*======================== Return ======================== */

	return (
		<div className='px-4'>
			<div className='space-y-2.5'>
				{searchData?.length > 0 &&
					searchData?.map((item, index) => (
						<div key={item.place_id} className='[&:not(:last-child)]:border-b border-solid border-grayD3 p-2.5 flex items-center gap-2.5' onClick={() => handleSelectLocation(item)}>
							<img src={locationIcon} alt='location' className='w-4.5 h-4.5' />
							<div className='space-y-0.5'>
								<p className='text-black37 text-sm font-semibold'>{item.structured_formatting.main_text}</p>
								{item.structured_formatting.secondary_text && <p className='text-gray90 text-xxs'>{item.structured_formatting.secondary_text}</p>}
							</div>
						</div>
					))}

				{search && !loading && searchData.length < 1 && (
					<div className='py-7 px-4'>
						<img src={notFoundImage} alt='not-found' className='h-[160px] mx-auto mb-4' />

						<p className='text-black37 font-semibold text-sm px-6 mb-1.5 text-center'>Hmm, no location found</p>
						<p className='text-black37 text-xxs text-center px-14'>We couldn't locate what you're looking for. How about trying something else?</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default DeliverySearchResult;
