import OwlCarousel from 'react-owl-carousel2';
import '../../common/css/owl.carousel.css';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueID } from '../../utils/constants';
import { getBannerList } from '../../store/reducers/banner';

const Banner = () => {
	/*======================== Props ======================== */

	const dispatch = useDispatch();
	const { data: banners, loading } = useSelector((state) => state.banner);

	useEffect(() => {
		dispatch(
			getBannerList({
				unquieid: uniqueID,
			}),
		);
	}, []);

	const bannerOptions = {
		items: 1,
		dots: false,
		loop: true,
		margin: 15,
		stagePadding: 30,
		stageOuterClass: '!px-1 owl-stage-outer mx-4 max-w-lg',
		stageClass: '!pl-0',
	};

	/*======================== Return ======================== */

	return (
		<div className='w-full banner-v2 mb-4.5 max-w-lg'>
			{banners?.length === 0 && loading && (
				<OwlCarousel options={bannerOptions}>
					{new Array(3).fill('').map((item, index) => (
						<div key={index} className='h-[140px] w-full cursor-pointer relative rounded-[10px]'>
							<div className='bg-grayD3 w-full h-full object-cover rounded-[10px]'></div>

							<div className='absolute left-2 bottom-2.5 flex gap-1'>
								{new Array(3).fill('').map((_item, dot) => (
									<div key={dot} className={twMerge('w-2 h-2 rounded-full', dot === index ? 'bg-white' : 'bg-white/40')}></div>
								))}
							</div>
						</div>
					))}
				</OwlCarousel>
			)}
			{banners?.length > 0 && (
				<OwlCarousel options={bannerOptions}>
					{banners
						?.filter((banner) => banner?.banner_image && banner?.banner_image.trim() !== '')
						?.map((item, index) => (
							<Link
								key={index}
								className='block h-[140px] w-full relative rounded-[10px]'
								to={item?.banner_link?.trim() || '#'}
								target={item?.banner_link?.trim() ? '_blank' : '_self'}
								rel={item?.banner_link?.trim() ? 'noreferrer' : undefined}>
								<img src={item?.banner_image} alt={`banner-${index}`} className='w-full h-full object-cover rounded-[10px]' />

								<div className='absolute left-2 bottom-2.5 flex gap-1'>
									{new Array(banners?.length).fill('').map((_item, dot) => (
										<div key={dot} className={twMerge('w-2 h-2 rounded-full', dot === index ? 'bg-white' : 'bg-white/40')}></div>
									))}
								</div>
							</Link>
						))}
				</OwlCarousel>
			)}
		</div>
	);
};

export default Banner;
