// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category .owl-dots .owl-dot.active {
  width: 6px !important;
  background-color: #a84198 !important;
}
.category .owl-item {
  overflow: visible !important;
}

.category .owl-carousel .owl-stage,
.che-slider .owl-carousel .owl-stage,
.recommand-slider .owl-carousel .owl-stage {
  padding-left: 0 !important;
}
.owl-carousel .owl-stage {
  display: flex;
  flex-wrap: wrap;
}
.category .owl-carousel .owl-item {
  overflow: hidden;
  border-radius: 5px;
}

.category .owl-dots .owl-dot {
  width: 6px;
  height: 6px;
  background: #e6e6e5;
  border-radius: 8px;
  margin: 0 2px;
  overflow: hidden;
  cursor: pointer;
  transition: width 0.3s ease;
}
.category .owl-dots {
  display: flex;
  justify-content: center;
  margin: 10px 0 0;
}
.category .owl-dots .owl-dot.active {
  width: 19px;
  background: #009a46;
}
.category .owl-carousel .owl-item {
  overflow: hidden;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Category/category.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,oCAAoC;AACtC;AACA;EACE,4BAA4B;AAC9B;;AAEA;;;EAGE,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".category .owl-dots .owl-dot.active {\n  width: 6px !important;\n  background-color: #a84198 !important;\n}\n.category .owl-item {\n  overflow: visible !important;\n}\n\n.category .owl-carousel .owl-stage,\n.che-slider .owl-carousel .owl-stage,\n.recommand-slider .owl-carousel .owl-stage {\n  padding-left: 0 !important;\n}\n.owl-carousel .owl-stage {\n  display: flex;\n  flex-wrap: wrap;\n}\n.category .owl-carousel .owl-item {\n  overflow: hidden;\n  border-radius: 5px;\n}\n\n.category .owl-dots .owl-dot {\n  width: 6px;\n  height: 6px;\n  background: #e6e6e5;\n  border-radius: 8px;\n  margin: 0 2px;\n  overflow: hidden;\n  cursor: pointer;\n  transition: width 0.3s ease;\n}\n.category .owl-dots {\n  display: flex;\n  justify-content: center;\n  margin: 10px 0 0;\n}\n.category .owl-dots .owl-dot.active {\n  width: 19px;\n  background: #009a46;\n}\n.category .owl-carousel .owl-item {\n  overflow: hidden;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
