import LocationIcon from '../../../../common/images/revamp/icon/location-purple.svg';
import LocationIconGray from '../../../../common/images/revamp/icon/location.svg';
import moreIcon from '../../../../common/images/revamp/icon/more.svg';
import editIcon from '../../../../common/images/revamp/icon/edit.svg';
import deleteIcon from '../../../../common/images/revamp/icon/trash.svg';
import Popover from '../../../Popover';
import DeleteAddress from './DeleteAddress';
import FormAddress from './FormAddress';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSavedList, clearSearch, getRecentList } from '../../../../store/reducers/location';
import homeIcon from '../../../../common/images/revamp/icon/home-gray.svg';
import officeIcon from '../../../../common/images/revamp/icon/office-gray.svg';
import otherIcon from '../../../../common/images/revamp/icon/tag-gray.svg';
import { setUser } from '../../../../store/reducers/user';

const DeliverySavedAddress = (props) => {
	/*======================== Props ======================== */

	const { handleCloseDelivery } = props;

	/*======================== UseState ======================== */

	const [showOption, setShowOption] = useState('');
	const [deleteForm, setDeleteForm] = useState({
		isOpen: false,
		data: undefined,
	});
	const [form, setForm] = useState({
		isOpen: false,
		data: undefined,
		type: '',
	});

	/*======================== Store ======================== */

	const dispatch = useDispatch();
	const user_id = useSelector((state) => state.user?.customerID || '');
	const lat = useSelector((state) => state.user?.latitude || '');
	const lng = useSelector((state) => state.user?.longitude || '');
	const address = useSelector((state) => state.user?.address || '');
	const { savedIsOpen, savedInitialForm, savedSearchResult, savedData } = useSelector((state) => state.location);

	/*======================== Handler ======================== */

	const handleForm = (type, item) => {
		console.log('item', item);
		switch (type) {
			case 'add':
				setForm({
					isOpen: true,
					data: {
						address,
						lat: Number(lat),
						lng: Number(lng),
					},
					type: 'add',
				});
				break;

			case 'edit':
				setForm({
					isOpen: true,
					data: {
						addressName: item.address_name,
						addressDetail: item.address_detail,
						type: item.type,
						contactName: item.contact_name,
						contactNumber: item.contact_number,
						lat: Number(item.latitude),
						lng: Number(item.longitude),
						id_customer_address: item.id,
					},
					type: 'edit',
				});
				break;

			default:
				setForm({
					isOpen: false,
					data: undefined,
					type: '',
				});
				break;
		}
	};
	const handleIcon = (type) => {
		switch (type) {
			case 'home':
				return homeIcon;
			case 'office':
				return officeIcon;
			default:
				return otherIcon;
		}
	};
	const handleSelectLocation = (location) => {
		console.log('handleSelectLocation: ', location);
		const { address, latitude, longitude, country, state, city, village, district, postal_code } = location;

		dispatch(
			setUser({
				address,
				latitude,
				longitude,
				country,
				state,
				city,
				village,
				district,
				postalCode: postal_code,
				primary_phone: location?.contact_number ?? '',
				detail: `${location?.address_name ?? ''}, ${location?.address_detail ?? ''}`,
			}),
		);
		handleCloseDelivery();
	};

	/*======================== UseEffect ======================== */

	useEffect(() => {
		dispatch(getSavedList(user_id));
		dispatch(getRecentList(user_id));
	}, []);

	useEffect(() => {
		if (savedIsOpen && savedInitialForm) {
			setForm({
				isOpen: true,
				data: {
					...savedInitialForm,
					lat: Number(savedSearchResult ? savedSearchResult.lat : lat),
					lng: Number(savedSearchResult ? savedSearchResult.lng : lng),
				},
				type: savedInitialForm.formType,
			});
			dispatch(clearSearch());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savedIsOpen, savedInitialForm]);

	/*======================== Return ======================== */

	return (
		<>
			<div className='px-4 relative'>
				<div className='flex justify-between items-center mb-4'>
					<span className='text-black37 font-semibold text-sm'>Saved addresses</span>
					{savedData?.length > 0 && (
						<span id='save_address_add_new' className='cursor-pointer font-semibold text-xs text-ufdPurple' onClick={() => handleForm('add')}>
							Add New
						</span>
					)}
				</div>
				{savedData?.length === 0 && (
					<button
						className='capitalize border border-solid border-ufdPurple bg-white text-ufdPurple w-full py-3 text-center text-xs font-semibold rounded hover:bg-grayD/10 transition-all flex gap-1 items-center justify-center'
						onClick={() => handleForm('add')}>
						<img src={LocationIcon} alt='location' />
						<span>Add New Address</span>
					</button>
				)}
				{savedData?.length > 0 && (
					<div className='space-y-2'>
						{savedData.map((item, index) => (
							<div key={index} className='px-4 py-2.5 border border-solid border-grayD3 rounded-md flex justify-between items-center gap-5 relative'>
								<div className='flex gap-2.5 items-center'>
									<img src={handleIcon(item.type)} alt={item.type} />
									<div className='space-y-0.5' onClick={() => handleSelectLocation(item)}>
										<p className='text-black37 text-sm font-semibold'>{item?.address_name}</p>
										<p className='text-gray90 text-xxs'>{item?.address}</p>
									</div>
								</div>
								<Popover
									content={
										<div className='rounded-md border border-solid border-grayD3 bg-white w-[140px] shadow-custom'>
											<div
												id='search_address_edit_location'
												className='px-3 py-2.5 border-b border-solid border-grayD3 flex justify-between items-center'
												onClick={() => {
													setShowOption('');
													handleForm('edit', item);
												}}>
												<span className='text-black37 font-semibold text-sm'>Edit</span>
												<img src={editIcon} alt='edit' />
											</div>
											<div
												id='search_address_delete_location'
												className='px-3 py-2.5 flex justify-between items-center'
												onClick={() => {
													setShowOption('');
													setDeleteForm({
														isOpen: true,
														data: item,
													});
												}}>
												<span className='text-black37 font-semibold text-sm'>Delete</span>
												<img src={deleteIcon} alt='delete' />
											</div>
										</div>
									}
									show={showOption === index}
									setShow={(value) => {
										setShowOption(value ? index : '');
									}}>
									<img src={moreIcon} alt='more' />
								</Popover>
							</div>
						))}
					</div>
				)}

				<DeleteAddress
					{...{ ...deleteForm }}
					onClose={() =>
						setDeleteForm({
							isOpen: false,
							data: undefined,
						})
					}
				/>
			</div>
			{form.isOpen && <FormAddress {...{ ...form }} onClose={() => handleForm('close')} />}
		</>
	);
};

export default DeliverySavedAddress;
