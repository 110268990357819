import { useEffect } from 'react';
import { Form, useLocation } from 'react-router-dom';

import Home from '../pages/home';
import HistoryList from '../components/Orders/OrdersHistoryList/OrderHistory';
import OrderHistoryDetails from '../components/Orders/OrderHistoryDetails/OrderHistoryDetails';
import FavoriteList from '../components/Restaurants/FavoriteList/FavoriteList';
import CustomGoogleMap from '../components/GoogleMaps';
import Outlet from '../pages/outlet/index';
import Search from '../components/Search';
import Summary from '../pages/summary';
import Payment from '../pages/payment';
import Tracking from '../pages/tracking';
import Success from '../pages/success';
import FormAddress from '../components/DeliveryLocation/LocationDetail/SavedAddress/FormAddress';

const useRouter = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const routes = [
		{
			exact: true,
			path: '/',
			component: Home,
		},
		{
			exact: true,
			path: '/search',
			component: Search,
		},
		{
			exact: true,
			path: '/google-map',
			component: CustomGoogleMap,
		},
		{
			exact: true,
			path: '/summary',
			component: Summary,
		},
		{
			exact: true,
			path: '/favourites',
			component: FavoriteList,
		},
		{
			exact: true,
			path: '/history/:orderType/:orderNumber',
			component: OrderHistoryDetails,
		},
		{
			exact: true,
			path: '/history/:orderType',
			component: HistoryList,
		},
		{
			exact: true,
			path: '/history',
			component: HistoryList,
		},
		{
			exact: true,
			path: '/payment',
			component: Payment,
		},
		{
			exact: true,
			path: '/outlet/:slug/:storeID?',
			component: Outlet,
		},
		{
			exact: true,
			path: '/tracking',
			component: Tracking,
		},
		{
			exact: true,
			path: '/payment/success',
			component: Success,
		},
	];

	return {
		routes,
	};
};

export default useRouter;
