import React from 'react';
import { Link } from 'react-router-dom';
import DeliveryLocation from '../DeliveryLocation';
import heart from '../../common/images/revamp/icon/heart.svg';
import history from '../../common/images/revamp/icon/history.svg';

const Header = () => {
	/*======================== Props ======================== */

	const headerMenu = [
		{
			id: 'homepage_favorite',
			name: 'heart',
			icon: heart,
			to: '/favourites',
		},
		{
			id: 'homepage_order_history',
			name: 'history',
			icon: history,
			to: '/history',
		},
	];

	/*======================== Return ======================== */

	return (
		<>
			<div className='w-full flex justify-between items-center gap-2.5'>
				<DeliveryLocation />
				<div className='flex gap-2.5 shrink-0'>
					{headerMenu.map((menu) => (
						<Link key={menu.name} to={menu.to} id={menu?.id}>
							<img className='w-8 h-8 grid place-content-center rounded-full bg-white/20 cursor-pointer' src={menu.icon} alt={menu.name} />
						</Link>
					))}
				</div>
			</div>
		</>
	);
};

export default Header;
