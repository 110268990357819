import deleteImage from '../../../../../common/images/revamp/images/delete-address.png';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSavedList } from '../../../../../store/reducers/location';
import { axiosInstance } from '../../../../../utils/helper';

const DeleteAddress = (props) => {
	/*======================== Props ======================== */

	const { isOpen, onClose, data } = props;

	/*======================== UseState ======================== */

	const [loading, setLoading] = useState(false);

	/*======================== Store ======================== */

	const dispatch = useDispatch();
	const user_id = useSelector((state) => state.user?.customerID || '');

	/*======================== Handler ======================== */

	const handleSubmit = async () => {
		setLoading(true);
		const formData = new FormData();

		formData.append('user_id', user_id);
		formData.append('id_customer_address', data.id);

		try {
			const response = await axiosInstance.post(`address/delete`, formData);
			if (response.status === 200) {
				onClose();
				dispatch(getSavedList(user_id));
			}
		} catch (error) {
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	/*======================== Return ======================== */

	return (
		<BottomSheet
			open={isOpen}
			onDismiss={() => {
				if (!loading) {
					onClose();
				}
			}}
			className='relative pointer-events-auto z-[9999999]'>
			<div className='p-4'>
				<img src={deleteImage} alt='no-outlet' className='h-[160px] mx-auto mb-4' />
				<p className='text-black37 font-semibold text-sm px-6 mb-1.5 text-center'>Delete your saved address?</p>
				<p className='text-black37 text-xxs mb-8 text-center'>Saved address make you easier to choose delivery location then manually set address every time you order.</p>

				<div className='grid grid-cols-2 gap-4'>
					<button
						id='search_address_cancel_delete'
						className='capitalize border border-solid border-ufdPurple text-ufdPurple w-full py-2.5 text-center text-xs font-semibold rounded hover:bg-ufdPurple hover:text-white transition-all disabled:cursor-not-allowed disabled:opacity-50'
						onClick={onClose}
						disabled={loading}>
						Cancel
					</button>
					<button
						id='search_address_confirm_delete'
						className='capitalize border border-solid border-ufdPurple bg-ufdPurple text-white w-full py-2.5 text-center text-xs font-semibold rounded hover:bg-ufdPurple/80 transition-all disabled:cursor-not-allowed disabled:opacity-50'
						onClick={handleSubmit}
						disabled={loading}>
						Yes, Sure
					</button>
				</div>
			</div>
		</BottomSheet>
	);
};

export default DeleteAddress;
